import ARAging from 'controlTower/widgets/ARAging';
import Collection from 'controlTower/widgets/Collection';
import DeliveryCompliance from 'controlTower/widgets/DeliveryCompliance';
import DeliveryExecution from 'controlTower/widgets/DeliveryExecution';
import DeliveryFailure from 'controlTower/widgets/DeliveryFailure';
import DeliveryPlanning from 'controlTower/widgets/DeliveryPlanning';
import SalesDistribution from 'controlTower/widgets/SalesDistribution';
import SalesPerformance from 'controlTower/widgets/SalesPerformance';
import ServiceOnTime from 'controlTower/widgets/ServiceOnTime';
import Universe from 'controlTower/widgets/Universe';
import AdherenceMetrics from './widgets/AdherenceMetrics';
import AdherenceMetrics_Marico from './widgets/AdherenceMetrics_Marico';
import HourlySales from './widgets/HourlySales';
import PhSupervisor from './widgets/PhSupervisor';
import RSPAdherenceMetrics from './widgets/RSPAdherenceMetrics';
import RSPPerformance from './widgets/RSPPerformance';
import RSPSalesPerformance from './widgets/RSPSalesPerformance';
import RSPSalesProductivity from './widgets/RSPSalesProductivity';
import RSPSalesProductivityBilled from './widgets/RSPSalesProductivityBilled';
import RSPSalesRepExceptionClusters from './widgets/RSPSalesRepExceptionClusters';
import SalesPerformance_Marico from './widgets/SalesPerformance_Marico';
import SalesProductivity from './widgets/SalesProductivity';
import SalesProductivityBilled from './widgets/SalesProductivityBilled';
import SalesProductivityBilled_Marico from './widgets/SalesProductivityBilled_Marico';
import SalesProductivity_Marico from './widgets/SalesProductivity_Marico';
import SalesRepExceptionClusters from './widgets/SalesRepExceptionClusters';
import SalesRepExceptionClusters_Marico from './widgets/SalesRepExceptionClusters_Marico';
import SalesRepScoreCard from './widgets/SalesRepScoreCard';
import AdherenceMetrics_Marico_Rural from './widgets/AdherenceMetrics_Marico_Rural';
import SalesRepExceptionClusters_Marico_Rural from './widgets/SalesRepExceptionClusters_Marico_Rural';
import SalesPerformance_Marico_Rural from './widgets/SalesPerformance_Marico_Rural';
import SalesProductivityBilledManual_Marico from './widgets/SalesProductivityBilledManual_Marico';
import SalesRepExceptionClusters_Marico_Rural_VSR from './widgets/SalesRepExceptionClusters_Marico_Rural_VSR';
import SalesPerformance_Marico_Rural_VSR from './widgets/SalesPerformance_Marico_Rural_VSR';
import AdherenceMetrics_Marico_Rural_VSR from './widgets/AdherenceMetrics_Marico_Rural_VSR';

const getLeafUrl = (id, idType) => `/sales/salesRepView/${id}${idType ? `?idType=${idType}` : ''}`;

const getDeliveryLeafUrl = (branchId) => `/dl/execution/${branchId}`;
const salesmanType = 'true';
export const widgetsMapping = [
  {
    widgetId: 0,
    id: 'CALL_COMPLIANCE',
    name: 'Visit Compliance',
    widget: SalesPerformance,
    url: '/client/metrics/salesPerformance',
    getLeafUrl,
    dashboard: 'sales',
  },
  {
    widgetId: 1,
    id: 'SALES_PRODUCTIVITY',
    name: 'Sales Productivity',
    widget: SalesProductivity,
    url: '/client/metrics/salesPerformance',
    getLeafUrl,
    dashboard: 'sales',
  },
  {
    widgetId: 2,
    id: 'PH_SUPERVISOR',
    name: 'View Seller Performance',
    widget: PhSupervisor,
    url: '/client/metrics/salesPerformance',
    getLeafUrl,
    dashboard: 'sales',
  },
  {
    widgetId: 3,
    id: 'SALES_REP_EXCEPTION_CLUSTERS',
    name: 'Sales Rep Exception Clusters',
    widget: SalesRepExceptionClusters,
    url: '/client/metrics/salesPerformance',
    getLeafUrl,
    dashboard: 'sales',
  },
  {
    widgetId: 4,
    id: 'ADHERENCE_METRICS',
    name: 'Adherence Metrics',
    widget: AdherenceMetrics,
    url: '/client/metrics/salesPerformance',
    getLeafUrl,
    dashboard: 'sales',
  },
  {
    widgetId: 5,
    id: 'SALES_PRODUCTIVITY_BILLED',
    name: 'Sales Productivity (Billed)',
    widget: SalesProductivityBilled,
    url: '/client/metrics/salesPerformance',
    getLeafUrl,
    dashboard: 'sales',
  },
  {
    widgetId: 6,
    id: 'SALES_REP_SCORE_CARD',
    name: 'Sales Rep Score Card',
    widget: SalesRepScoreCard,
    url: '/client/metrics/salesPerformance',
    getLeafUrl,
    dashboard: 'sales',
  },
  {
    widgetId: 7,
    id: 'HOURLY_SALES',
    name: 'Hourly Bills Count',
    widget: HourlySales,
    url: '/client/metrics/salesPerformance',
    getLeafUrl,
    dashboard: 'sales',
  },
  {
    widgetId: 8,
    id: 'SERVICE_ON_TIME',
    name: 'Service On Time',
    widget: ServiceOnTime,
    url: '/client/metrics/deliveryLeadTime',
    dashboard: 'delivery',
    getLeafUrl: getDeliveryLeafUrl,
  },
  {
    widgetId: 9,
    id: 'DELIVERY_PLANNING',
    name: 'Delivery Planning',
    widget: DeliveryPlanning,
    url: '/client/metrics/deliveryPlanning',
    dashboard: 'delivery',
    getLeafUrl: getDeliveryLeafUrl,
  },
  {
    widgetId: 10,
    id: 'DELIVERY_EXECUTION',
    name: 'Delivery Execution',
    widget: DeliveryExecution,
    url: '/client/metrics/deliveryExecution',
    dashboard: 'delivery',
    getLeafUrl: getDeliveryLeafUrl,
  },
  {
    widgetId: 11,
    id: 'LOCATION_COMPLIANCE',
    name: 'Location Compliance',
    widget: DeliveryCompliance,
    url: '/client/metrics/deliveryCompliance',
    dashboard: 'delivery',
    getLeafUrl: getDeliveryLeafUrl,
  },
  {
    widgetId: 12,
    id: 'DELIVERY_FAILURE',
    name: 'Delivery Failure',
    widget: DeliveryFailure,
    url: '/client/metrics/deliveryExecutionFailures',
    dashboard: 'delivery',
    getLeafUrl: getDeliveryLeafUrl,
  },
  {
    widgetId: 13,
    id: 'COLLECTION',
    name: 'Collection',
    widget: Collection,
    url: '/client/metrics/collection',
    getLeafUrl,
    dashboard: 'sales',
  },
  {
    widgetId: 14,
    id: 'AR_AGING',
    name: 'AR Aging',
    widget: ARAging,
    url: '/client/metrics/arAging',
    dashboard: 'sales',
  },
  {
    widgetId: 15,
    id: 'SALES_BY_CHANNEL',
    name: 'Sales By Channel',
    widget: SalesDistribution,
    url: '/client/metrics/salesByBrand',
    dashboard: 'sales',
  },
  {
    widgetId: 16,
    id: 'UNIVERSE',
    name: 'Universe',
    widget: Universe,
    url: '/client/metrics/geotagging',
    dashboard: 'sales',
  },
  {
    widgetId: 17,
    id: 'CALL_COMPLIANCE_RSP',
    name: 'RSP Visit Compliance',
    widget: RSPSalesPerformance,
    url: '/client/metrics/rsp/salesPerformance',
    getLeafUrl,
    dashboard: 'rspsales',
  },
  {
    widgetId: 18,
    id: 'ADHERENCE_METRICS_RSP',
    name: 'Adherence Metrics',
    widget: RSPAdherenceMetrics,
    url: '/client/metrics/rsp/salesPerformance',
    getLeafUrl,
    dashboard: 'rspsales',
  },
  {
    widgetId: 19,
    id: 'SALES_REP_EXCEPTION_CLUSTERS_RSP',
    name: 'Sales Rep Exception Clusters',
    widget: RSPSalesRepExceptionClusters,
    url: '/client/metrics/rsp/salesPerformance',
    getLeafUrl,
    dashboard: 'rspsales',
  },
  {
    widgetId: 20,
    id: 'SALES_PRODUCTIVITY_RSP',
    name: 'Sales Productivity RSP Market Orders',
    widget: RSPSalesProductivity,
    url: '/client/metrics/rsp/salesPerformance',
    getLeafUrl,
    dashboard: 'rspsales',
  },
  {
    widgetId: 21,
    id: 'SALES_PRODUCTIVITY_BILLED_RSP',
    name: 'Sales Productivity at RSP Level(DMS)',
    widget: RSPSalesProductivityBilled,
    url: '/client/metrics/rsp/salesPerformance',
    getLeafUrl,
    dashboard: 'rspsales',
  },
  {
    widgetId: 22,
    id: 'PERFORMANCE_RSP',
    name: 'RSP Performance',
    widget: RSPPerformance,
    url: '/client/metrics/rsp/salesPerformance',
    getLeafUrl,
    dashboard: 'rspsales',
  },
  // Marico widgets starts
  {
    widgetId: 23,
    id: 'SALES_REP_EXCEPTION_CLUSTERS_MARICO',
    name: 'DSR Exception Clusters',
    widget: SalesRepExceptionClusters_Marico,
    url: '/client/metrics/salesPerformance',
    getLeafUrl,
    dashboard: 'sales',
  },
  {
    widgetId: 24,
    id: 'ADHERENCE_METRICS_MARICO',
    name: 'Geo-Adherence Metrics',
    widget: AdherenceMetrics_Marico,
    url: '/client/metrics/salesPerformance',
    getLeafUrl,
    dashboard: 'sales',
  },
  {
    widgetId: 25,
    id: 'SALES_PRODUCTIVITY_BILLED_MARICO',
    name: 'Sales Productivity (Billed-PDA)',
    widget: SalesProductivityBilled_Marico,
    url: '/client/metrics/salesPerformance',
    getLeafUrl,
    dashboard: 'sales',
  },
  {
    widgetId: 26,
    id: 'SALES_PRODUCTIVITY_MARICO',
    name: 'Sales Productivity',
    widget: SalesProductivity_Marico,
    url: '/client/metrics/salesPerformance',
    getLeafUrl,
    dashboard: 'sales',
  },
  {
    widgetId: 27,
    id: 'CALL_COMPLIANCE_MARICO',
    name: 'Visit Compliance',
    widget: SalesPerformance_Marico,
    url: '/client/metrics/salesPerformance',
    getLeafUrl,
    dashboard: 'sales',
  },
  {
    widgetId: 28,
    id: 'VISIT_COMPLIANCE_MARICO_RSP',
    name: 'Visit Compliance - PSR',
    widget: SalesPerformance_Marico_Rural,
    url: '/client/metrics/rsp/salesPerformance',
    getLeafUrl,
    dashboard: 'rspsales',
  },
  {
    widgetId: 29,
    id: 'EXCEPTION_CLUSTERS_MARICO_RSP',
    name: 'PSR Exception Clusters',
    widget: SalesRepExceptionClusters_Marico_Rural,
    url: '/client/metrics/rsp/salesPerformance',
    getLeafUrl,
    dashboard: 'rspsales',
  },
  {
    widgetId: 30,
    id: 'ADHERENCE_METRICS_MARICO_RSP',
    name: 'Geo Adherence Metrics - PSR',
    widget: AdherenceMetrics_Marico_Rural,
    url: '/client/metrics/rsp/salesPerformance',
    getLeafUrl,
    dashboard: 'rspsales',
  },
  {
    widgetId: 31,
    id: 'SALES_PRODUCTIVITY_BILLED_MANUAL_MARICO',
    name: 'Sales Productivity(Billed-Manual)',
    widget: SalesProductivityBilledManual_Marico,
    url: '/client/metrics/salesPerformance',
    getLeafUrl,
    dashboard: 'sales',
  },
  {
    widgetId: 32,
    id: 'VISIT_COMPLIANCE_MARICO_VSR_RSP',
    name: 'Visit Compliance - VSR',
    widget: SalesPerformance_Marico_Rural_VSR,
    url: '/client/metrics/rsp/salesPerformance',
    getLeafUrl,
    salesmanType,
    dashboard: 'rspsales',
  },
  {
    widgetId: 33,
    id: 'ADHERENCE_METRICS_MARICO_VSR_RSP',
    name: 'Geo Adherence Metrics - VSR',
    widget: AdherenceMetrics_Marico_Rural_VSR,
    url: '/client/metrics/rsp/salesPerformance',
    getLeafUrl,
    salesmanType,
    dashboard: 'rspsales',
  },
  {
    widgetId: 34,
    id: 'EXCEPTION_CLUSTERS_MARICO_VSR_RSP',
    name: 'VSR Exception Clusters',
    widget: SalesRepExceptionClusters_Marico_Rural_VSR,
    url: '/client/metrics/rsp/salesPerformance',
    getLeafUrl,
    salesmanType,
    dashboard: 'rspsales',
  },
  // Marico widgets ends
];
