import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box } from '@material-ui/core';
import { Doughnut } from 'react-chartjs-2';
import { useFetchWidgetData } from './../ctHooks';

const useStyles = makeStyles((theme) => ({
  topGridItem: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '50%',
    },
  },
}));

const doughnutChartFailureOptions = {
  maintainAspectRatio: false,
  responsive: true,
  title: {
    text: 'Failure Reasons',
    display: 'true',
    position: 'top',
  },
  legend: {
    position: 'bottom',
    // labels: {
    //   fontSize: 10,
    // },
    // display: false,
  },
  plugins: {
    datalabels: {
      color: 'rgb(0,0,0,0.87)',
      font: {
        weight: 400,
      },
    },
  },
};
const doughnutChartReturnsOptions = {
  maintainAspectRatio: false,
  responsive: true,
  title: {
    text: 'Failure Actions',
    display: 'true',
    position: 'top',
  },

  legend: {
    position: 'bottom',
    // labels: {
    //   fontSize: 13,
    // },

    // display: false,
  },
  plugins: {
    datalabels: {
      color: 'rgb(0,0,0,0.87)',
      font: {
        weight: 400,
      },
    },
  },
};

const notAttemptedLastSorter = (a, b) => {
  if (a.reason === 'Not Attempted') return 1;
  if (b.reason === 'Not Attempted') return -1;
  return 0;
};

export default function DeliveryFailure({ url, branchesView, branchData, setPenultimate }) {
  const classes = useStyles();
  const data = useFetchWidgetData({ url, branchesView, branchData, setPenultimate });

  const failureReasonsChartData = useMemo(
    () => ({
      labels: data ? data.failures.sort(notAttemptedLastSorter).map((f) => f.reason) : [],
      datasets: [
        {
          label: 'By Value',
          data: data ? data.failures.sort(notAttemptedLastSorter).map((f) => f.count) : [],
          backgroundColor: [
            // ...[
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(75, 192, 192, 0.6)',
            'rgba(255, 99, 132, 0.6)',
            // ].slice(4 - dataToUse ? dataToUse.failures.length : 4),
            'rgba(0, 0, 0, 0.3)',
          ],
        },
      ],
    }),
    [data]
  );

  const failureActionsChartData = useMemo(
    () => ({
      labels: ['Retry', 'Return', 'No Action'],
      datasets: [
        {
          label: 'By Value',
          data: data
            ? [
                data.returns.retry,
                data.returns.srn,
                data.returns.total - (data.returns.retry + data.returns.srn),
              ]
            : [0, 0, 0],
          backgroundColor: [
            'rgba(75, 192, 192, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(0, 0, 0, 0.3)',
          ],
        },
      ],
    }),
    [data]
  );

  if (!data) return null;

  return (
    <Box
      // className={classes.root}
      flexDirection="column"
      display="flex"
      justifyContent="center"
      height={1}
    >
      <Box height={1} pt={1}>
        <Grid
          container
          justify="space-around"
          alignItems="center"
          style={{ height: '100%' }}
          spacing={2}
        >
          <Grid item xs={12} sm={6} className={classes.topGridItem}>
            <Doughnut data={failureReasonsChartData} options={doughnutChartFailureOptions} />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.topGridItem}>
            <Doughnut data={failureActionsChartData} options={doughnutChartReturnsOptions} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
