import React, { memo, useState } from 'react';
import { Typography, Box, Divider, Grid, Tooltip } from '@material-ui/core';
import { useColor } from 'controlTower/ctHooks';
import { isVal } from 'utils/utils';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export const Caption = ({ children }) => (
  <Typography variant="caption" color="textSecondary" display="block" align="center">
    {children}
  </Typography>
);

export const CaptionWithValue = memo(
  ({
    value,
    label,
    id = false,
    raw,
    quartile,
    reverse,
    bgColor,
    options = { boundary: true },
    tooltip = '',
  }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

    const color = useColor(id, raw, value, quartile, reverse);

    return (
      <>
        {tooltip?.length >= 1 ? (
          <Tooltip placement="top" title={tooltip} arrow>
            <Box
              boxShadow={0}
              mx={0.5}
              borderRadius={8}
              bgcolor={bgColor || color.color}
              borderColor="grey.400"
              border={options.boundary ? 1 : 0}
              onClick={() => {
                isMobileView ? setShowTooltip(!showTooltip) : null;
              }}
            >
              <Box class="bg-primary rounded-t-md text-center" pt={0.5} px={1}>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  display="block"
                  align="center"
                  class="text-white"
                >
                  {label}
                </Typography>
              </Box>
              <Divider />
              <Box mb={0.5} px={1}>
                <Typography display="block" align="center" style={{ paddingTop: 4 }}>
                  {value}
                </Typography>
              </Box>
            </Box>
          </Tooltip>
        ) : (
          <Box
            boxShadow={0}
            mx={0.5}
            borderRadius={8}
            bgcolor={bgColor || color.color}
            borderColor="grey.400"
            border={options.boundary ? 1 : 0}
          >
            <Box class="bg-primary rounded-t-md text-center" pt={0.5} px={1}>
              <Typography
                variant="caption"
                color="textSecondary"
                display="block"
                align="center"
                class="text-white"
              >
                {label}
              </Typography>
            </Box>
            <Divider />
            <Box mb={0.5} px={1}>
              <Typography display="block" align="center" style={{ paddingTop: 4 }}>
                {value}
              </Typography>
            </Box>
          </Box>
        )}
      </>
    );
  }
);

export const renderField = (width = 4) => (
  [label, value, quartile, raw, reverse = false, id = false, tooltip = ''],
  i
) => (
  <Grid item xs={width} key={i}>
    <CaptionWithValue
      value={isVal(value) ? value : '--'}
      label={label}
      raw={raw}
      quartile={quartile}
      reverse={reverse}
      id={id}
      tooltip={tooltip}
    ></CaptionWithValue>
  </Grid>
);
