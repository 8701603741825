import { Box, Grid } from '@material-ui/core';
import { renderField } from 'components/TypographyUtils';
import { blue } from 'controlTower/chartUtils/colors';
import React from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import { format1, isVal } from 'utils/utils';
import { useFetchWidgetData } from '../ctHooks';

const funnelOptions = {
  maintainAspectRatio: false,
  responsive: true,
  legend: {
    display: false,
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        return data?.datasets[0]?.label?.[Number(tooltipItem?.index)][0];
      },
      afterBody: function (tooltipItem, data) {
        return data?.datasets[0]?.label?.[Number(tooltipItem[0]?.index)]?.slice(1);
      },
    },
  },
  plugins: {
    datalabels: {
      color: 'rgb(0,0,0,0.87)',
      font: {
        weight: 400,
      },
      clamp: true,
    },
  },

  scales: {
    yAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

export default function AdherenceMetrics_Marico({
  url,
  branchesView,
  branchData,
  quartile: q,
  setPenultimate,
}) {
  const data = useFetchWidgetData({ url, branchesView, branchData, setPenultimate });
  const { salesmanCount, reportedCount, plannedBeatCompliance, beatCompliantReported } = data;

  const funnelData = {
    labels: ['DSR Universe', 'DSRs Present', 'Beat Adherent DSRs', 'Geo Compliant DSRs'],
    datasets: [
      {
        data: [salesmanCount, reportedCount, plannedBeatCompliance, beatCompliantReported],
        backgroundColor: blue,
        label: [
          ['No. of DSRs active'],
          ['No. of DSRs that have reported'],
          ['No. of  DSRs who have taken atleast one order', 'from the Designed SalesBeat'],
          ['No. of DSRs present at location of Outlets', 'in Designed Beat'],
        ],
      },
    ],
  };

  return (
    <Box height={1} flexDirection="column" display="flex" justifyContent="space-evenly">
      <Box height={3 / 4} pt={1}>
        <HorizontalBar options={funnelOptions} data={funnelData} />
      </Box>
      <Box height={1 / 4} py={1}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          style={{ height: '100%' }}
        >
          {[
            [
              '% Outlets Visited',
              format1(
                ((data.beatOrderRetailersCount -
                  data.beatAwayOrderRetailersCount +
                  (data.beatFailedOrderRetailersCount - data.beatAwayFailedOrderRetailersCount)) *
                  100) /
                  data.beatRetailersCount
              ) + '%',
              null,
              null,
              false,
              false,
              '% of outlets visited according to the designed beat and has orders.',
            ],
            [
              '% Orders within Geofence',
              isVal(data.awayOrders) ? 100 - data.awayOrders + '%' : '--',
              q?.awayOrders,
              100 - data.awayOrders,
              false,
              false,
              '% of outlets from the designed beats which have given orders to our DSR.',
            ],
          ].map(renderField(6))}
        </Grid>
      </Box>
    </Box>
  );
}
