import { Box, LinearProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Link from 'components/Link';
import React, { Suspense, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useGState } from 'state/store';

const useStyles = makeStyles(() => ({
  root: {
    height: 'calc(100% - 4px)',
  },
  widgetContent: {
    height: 'calc(100% - 4px)',
  },
  errBoundary: {
    height: 'calc(100% - 4px)',
    display: 'flex',
    justifyContent: 'center',
  },
}));

const isSSR = typeof window === 'undefined';

export default function WidgetContainer({
  name,
  height,
  Widget,
  url,
  getLeafUrl,
  branchesView = false,
  branchData,
  quartile,
  id,
  widgetId,
  salesmanType,
}) {
  const classes = useStyles(height);
  const [penultimate, setPenultimate] = useState(false);
  const { date, branch, node } = useGState((s) => ({
    date: s.date,
    branch: s.branch,
    node: s.node,
  }));

  return (
    <Box
      m={1 / 4}
      border={1}
      borderRadius={4}
      borderColor="grey.300"
      display="flex"
      flexDirection="column"
      bgcolor="white"
      className={classes.root}
    >
      {!isSSR && (
        <Suspense
          fallback={
            <Heading
              name={name}
              branchData={branchData}
              branch={branch}
              branchesView={branchesView}
              id={id}
              widgetId={widgetId}
              loading
              salesmanType={salesmanType}
            />
          }
        >
          <Heading
            getLeafUrl={getLeafUrl}
            name={name}
            branchData={branchData}
            branch={branch}
            id={id}
            widgetId={widgetId}
            penultimate={penultimate}
            branchesView={branchesView}
            salesmanType={salesmanType}
          />
          <ErrorBoundary
            FallbackComponent={() => (
              <div class={classes.errBoundary}>
                <p class="self-center text-inactive text-base" style={{ textAlign: 'center' }}>
                  Something went wrong <br /> We will be looking into it
                </p>
              </div>
            )}
          >
            <Box className={classes.widgetContent} m={1} mt={0}>
              {branchesView ? (
                <Widget branchesView={branchesView} branchData={branchData} quartile={quartile} />
              ) : (
                Widget &&
                date &&
                branch &&
                node && (
                  <Widget branchesView={branchesView} url={url} setPenultimate={setPenultimate} />
                )
              )}
            </Box>
          </ErrorBoundary>
        </Suspense>
      )}
    </Box>
  );
}

function Heading({
  getLeafUrl,
  branchData: nodeData,
  penultimate,
  branchesView,
  name,
  loading,
  id,
  widgetId,
  salesmanType,
}) {
  // const user = useUser();

  function renderBranchesViewWidgetHeading() {
    return nodeData.penultimate ? (
      getLeafUrl ? (
        <Box className="bg-gray-200 mx-1.5 rounded flex justify-center">
          <Link to={getLeafUrl(nodeData.id, nodeData.type === 'NODE' ? 'branchId' : 'stlId')}>
            <Typography align="center">{nodeData.name}</Typography>
          </Link>
        </Box>
      ) : (
        <Box className="bg-gray-200 mx-1.5 rounded flex justify-center">
          <Typography align="center">{nodeData.name}</Typography>
        </Box>
      )
    ) : (
      <Box className="bg-gray-200 mx-1.5 rounded flex justify-center">
        <Link
          to={`/ct/Branches/${widgetId}/${nodeData.id}/${nodeData.type}?${
            salesmanType ? 'salesmanType=VSR' : ''
          }`}
        >
          <Typography align="center">{nodeData.name}</Typography>
        </Link>
      </Box>
    );
  }

  function renderHomeViewWidgetHeading() {
    return penultimate ? (
      getLeafUrl ? (
        <Box className="bg-gray-200 mx-1.5 rounded flex justify-center">
          <Link to={getLeafUrl(id, 'branchId')}>
            <Typography align="center">{name}</Typography>
          </Link>
        </Box>
      ) : (
        <Box className="bg-gray-200 mx-1.5 rounded flex justify-center">
          <Typography align="center">{name}</Typography>
        </Box>
      )
    ) : (
      <Box className="bg-gray-200 mx-1.5 rounded flex justify-center">
        <Link to={`/ct/Branches/${widgetId}/${id}/0?${salesmanType ? 'salesmanType=VSR' : ''}`}>
          <Typography align="center">{name}</Typography>
        </Link>
      </Box>
    );
  }

  return (
    <Box>
      <Box height={8}>{loading && <LinearProgress color="secondary" />}</Box>
      <Box>{branchesView ? renderBranchesViewWidgetHeading() : renderHomeViewWidgetHeading()}</Box>
    </Box>
  );
}
