import * as preDefinedcolors from 'controlTower/chartUtils/colors';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { ga, useGDispatch, useGState } from 'state/store';
import fetch from 'utils/fetch';
import { formatDayTime24 } from '../utils/utils';

export function useColor(id, raw, value, quartile, reverse) {
  const { colorCode } = useGState((s) => s.settings);
  let val = Number(raw || value);

  if (quartile?.length) {
    if (isNaN(val) || val > Number.MAX_SAFE_INTEGER) {
      return { color: 'white', default: true };
    }

    const colors = reverse
      ? ['$green', '$yellow', '$amber', '$red']
      : ['$red', '$amber', '$yellow', '$green'];
    let i = 0;
    for (; i < quartile.length; i += 1) {
      if (val < quartile[i]) {
        break;
      }
    }
    const color = preDefinedcolors[colors[i]?.slice(1)];
    return { color, default: false };
  }

  if (
    !id ||
    !colorCode[id] ||
    !colorCode[id].boundaries ||
    !colorCode[id].boundaries.length ||
    isNaN(val)
  )
    return { color: 'white', default: true };

  const colors = colorCode[id].colors || ['$red', '$yellow', '$green'];
  let i = 0;
  for (; i < colorCode[id].boundaries.length; i += 1) {
    if (val < colorCode[id].boundaries[i]) {
      break;
    }
  }

  const color = colors[i].startsWith('$') ? preDefinedcolors[colors[i].slice(1)] : colors[i];

  return { color, default: false };
}

export function useFetchWidgetData({ url, branchesView, branchData, setPenultimate = () => {} }) {
  const { date, node } = useGState((s) => ({ date: s.date, node: s.node }));
  const gDispatch = useGDispatch();

  const { data } = useQuery(
    !branchesView && [url, { url, date, nodeId: node.id }],
    (_, { url, date, nodeId }) => fetch(`${url}?date=${date}&id=${nodeId}`),
    {
      suspense: !branchesView,
    }
  );

  useEffect(() => {
    if (data) {
      setPenultimate(data.penultimate);
      if (data.updatedAt) {
        gDispatch([ga.LAST_UPDATED, `updated at ${formatDayTime24(new Date(data.updatedAt))}`]);
        return () => gDispatch([ga.LAST_UPDATED, '']);
      }
    }
  }, [data, setPenultimate, gDispatch]);

  return branchData || data;
}
