import React, { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { Box } from '@material-ui/core';
import { format2 } from 'utils/utils';
import { red, blue, green, yellow } from 'controlTower/chartUtils/colors';
import { useFetchWidgetData } from './../ctHooks';

const geoTagChartOptions = {
  maintainAspectRatio: false,
  responsive: true,
  legend: {
    display: false,
  },
  title: {
    display: true,
    text: '',
  },
  plugins: {
    datalabels: {
      align: 'end',
      anchor: 'end',
      color: 'rgb(0,0,0,0.87)',
      font: {
        weight: 400,
      },
      display: (ctx) => ctx.dataIndex,
      formatter: (value, ctx) => {
        if (!value || !ctx.dataset.data[0]) return '';
        return format2((100 * value) / ctx.dataset.data[0]) + ' %';
      },
    },
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
        gridLines: {
          display: false,
        },
      },
    ],
  },
};

export default function Universe({ url, branchesView, branchData, setPenultimate }) {
  const data = useFetchWidgetData({ url, branchesView, branchData, setPenultimate });

  const chartData = useMemo(
    () => ({
      labels: ['Total', 'Geotagged', 'Image Verified', 'Phone Verified'],
      datasets: [
        {
          label: 'Geotagging Status',
          data: [data.total, data.geotagged, data.imageVerified, data.phoneVerified],
          backgroundColor: [red, blue, yellow, green],
        },
      ],
    }),
    [data]
  );

  return (
    <Box height={1}>
      <Bar data={chartData} options={geoTagChartOptions}></Bar>
    </Box>
  );
}
