import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { renderField } from 'components/TypographyUtils';
import React from 'react';
import { format0, format1, isVal } from 'utils/utils';
import { useFetchWidgetData } from './../ctHooks';

const columns = [
  {
    id: 'subClass',
    label: 'Sub Class',
    align: 'center',
    value: (data) => (data.row.channel ? data.row.channel : '--'),
  },
  {
    id: 'todayBeat',
    label: 'Total Sub',
    align: 'center',
    value: (data) => data.row.retailerCount,
  },
  {
    id: 'avgBeat',
    label: 'Avg Sub T/o (lacs)',
    align: 'center',
    value: (data) => (data.row.turnover ? format0(data.row.turnover / 100000) : '--'),
  },
  {
    id: 'subVisited',
    label: 'Unique Sub Visited (MTD)',
    align: 'center',
    value: (data) => data.row.visited,
  },
  {
    id: 'visitSaliency',
    label: '% Visit Saliency',
    align: 'center',
    total: () => '100%',
    value: (data) =>
      format0(
        (data.row.visited * ((data.row?.mtdFrequency || 0) / data.row.retailerCount) * 100) /
          data.total.weightedVisits
      ) + '%',
  },
  {
    id: 'notVisited',
    label: 'Not Visited',
    align: 'center',
    value: (data) => data.row.retailerCount - data.row.visited,
  },
  {
    id: 'l3m',
    label: 'L3M Visit Frequency',
    align: 'center',
    value: (data) => format1(data.row.l3mFrequency / data.row.retailerCount),
  },
  {
    id: 'mtdFreqVisit',
    label: 'MTD Visit Frequency',
    align: 'center',
    value: (data) => format1(data.row.mtdFrequency / data.row.retailerCount),
  },
];

export default function RSPAdherenceMetrics({
  url,
  branchesView,
  branchData,
  quartile: q,
  setPenultimate,
}) {
  const data = useFetchWidgetData({ url, branchesView, branchData, setPenultimate });
  // const rowCount = data?.channelMetrics?.slice(0, 5)?.length;
  const total = data?.channelMetrics
    .sort((a, b) => a.channel?.localeCompare(b.channel) ?? 1)
    .slice(0, 6)
    ?.reduce(
      (acc, row) => ({
        channel: 'Total',
        retailerCount: acc.retailerCount + row.retailerCount,
        l3mFrequency: acc.l3mFrequency + row.l3mFrequency,
        mtdFrequency: acc.mtdFrequency + row.mtdFrequency,
        turnover: acc.turnover + row.turnover,
        visited: acc.visited + row.visited,
        weightedVisits:
          acc.weightedVisits + (row.visited * (row.mtdFrequency || 0)) / row.retailerCount,
      }),
      {
        retailerCount: 0,
        l3mFrequency: 0,
        mtdFrequency: 0,
        turnover: 0,
        visited: 0,
        weightedVisits: 0,
      }
    );

  return (
    <Box height={1} flexDirection="column" display="flex" justifyContent="space-evenly">
      <Box height={1 / 4} pt={2}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          style={{ height: '100%' }}
        >
          {[
            [
              'Beat Compliant',
              `${data.plannedBeatCompliance} (${format1(
                (100 * data.plannedBeatCompliance) / data.salesmenWithOrders
              )}%)`,
              q?.plannedBeatCompliance,
              data.plannedBeatCompliance,
            ],
            [
              'Sub Location Compliant',
              isVal(data.awayOrders) ? 100 - data.awayOrders + '%' : '--',
              q?.awayOrders,
              100 - data.awayOrders,
            ],
          ].map(renderField(6))}
        </Grid>
      </Box>
      <Box height={3 / 4} py={2}>
        <TableContainer>
          <Table stickyHeader aria-label="Channel Metrics table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    style={{
                      border: '1px solid black',
                      padding: 0,
                      backgroundColor: '#07162a',
                      color: 'white',
                      wordWrap: 'break-word',
                    }}
                    key={column.id}
                    align={column.align}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.channelMetrics
                .sort((a, b) => a.channel?.localeCompare(b.channel) ?? 1)
                .slice(0, 6)
                .map((row, index) => (
                  <TableRow hover tabIndex={-1} key={index}>
                    {columns.map((column) => {
                      const value = column.value({ row: row, total });
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            border: '1px solid black',
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            padding: 0,
                          }}
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              <TableRow hover tabIndex={-1} key="total">
                {columns.map((column) => {
                  const value = column.total ? column.total() : column.value({ row: total, total });
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        border: '1px solid black',
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        padding: 0,
                      }}
                    >
                      {value}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
