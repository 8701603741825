import { Box, Grid } from '@material-ui/core';
import { renderField } from 'components/TypographyUtils';
import React from 'react';
import { useCurrencyFormatter } from 'utils/customHooks';
import { format1, isVal } from 'utils/utils';
import { useFetchWidgetData } from '../ctHooks';

export default function SalesProductivity_Marico({
  url,
  branchesView,
  branchData,
  quartile,
  setPenultimate,
}) {
  const data = useFetchWidgetData({ url, branchesView, branchData, setPenultimate });
  const formatCurrency = useCurrencyFormatter();

  return (
    <Box height={1} flexDirection="column" display="flex" justifyContent="space-evenly">
      <Box height={3 / 8} pt={2}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          style={{ height: '100%' }}
        >
          {[
            [
              'Avg Index Sales/DSR',
              isVal(data.sales) ? formatCurrency(data.sales / data.salesmenWithOrders) : '--',
              quartile?.sales,
              data.sales / data.salesmenWithOrders,
              false,
              false,
              'Avg Index sales per DSR (with orders for the day).',
            ],
            [
              'Avg PC/DSR',
              format1(data.orderRetailersCount / data.salesmenWithOrders),
              null,
              null,
              false,
              false,
              'Avg of ratio of Productive calls to DSR with Orders.',
            ],
          ].map(renderField(6))}
        </Grid>
      </Box>

      <Box height={3 / 8} pb={2}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          style={{ height: '100%' }}
        >
          {[
            [
              'Outlet Productivity %',
              isVal(data.productivity) ? data.productivity + '%' : '--',
              quartile?.productivity,
              data.productivity,
              false,
              false,
              '% of outlets in designed beats with orders for the day.',
            ],
            [
              'Total BPD/DSR',
              data.totalOrderBrands
                ? format1(data.totalOrderBrands / data.salesmenWithOrders)
                : '--',
              null,
              null,
              false,
              false,
              'Avg no. of distinct count of Brands per DSR with orders.',
            ],
          ].map(renderField(6))}
        </Grid>
      </Box>
      <Box height={1 / 4} py={1}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          style={{ height: '100%' }}
        >
          {[
            [
              'DSR with orders',
              !data?.salesmenWithOrders && data.salesmenWithOrders !== 0
                ? '--'
                : data.salesmenWithOrders,
              null,
              null,
              false,
              false,
              'Count of DSRs with Orders',
            ],
          ].map(renderField(6))}
        </Grid>
      </Box>
    </Box>
  );
}
