import { Box, Grid } from '@material-ui/core';
import { renderField } from 'components/TypographyUtils';
import React from 'react';
import { useFetchWidgetData } from './../ctHooks';

export default function SalesRepScoreCard({
  url,
  branchesView,
  branchData,
  quartile,
  setPenultimate,
}) {
  const data = useFetchWidgetData({ url, branchesView, branchData, setPenultimate });
  const {
    beatCompliantScore,
    marketEntryScore,
    timeInMarketScore,
    billsAndOrdersScore,
    edgeScore,
    assortmentAdherenceScore,
    finalExecutionScore,
  } = data;

  return (
    <Box height={1} flexDirection="column" display="flex" justifyContent="space-evenly">
      <Box height={3 / 8} pt={2}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          style={{ height: '100%' }}
        >
          {[
            ['Beat Compliant', beatCompliantScore, quartile?.beatCompliantScore],
            ['Market Entry', marketEntryScore, quartile?.marketEntryScore],
            ['Time in Market', timeInMarketScore, quartile?.timeInMarketScore],
          ].map(renderField(4))}
        </Grid>
      </Box>

      <Box height={3 / 8} pb={2}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          style={{ height: '100%' }}
        >
          {[
            ['Bills & Orders', billsAndOrdersScore, quartile?.billsAndOrdersScore],
            ['Edge Score', edgeScore, quartile?.edgeScore],
            [
              'Suggested Order Adherence',
              assortmentAdherenceScore,
              quartile?.assortmentAdherenceScore,
            ],
          ].map(renderField(4))}
        </Grid>
      </Box>

      <Box height={1 / 4} py={1}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          style={{ height: '100%' }}
        >
          {[['Final Sales Rep Score', finalExecutionScore, quartile?.finalExecutionScore]].map(
            renderField(6)
          )}
        </Grid>
      </Box>
    </Box>
  );
}
