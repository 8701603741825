import { Box, Grid } from '@material-ui/core';
import { renderField } from 'components/TypographyUtils';
import React from 'react';
import { useSettings } from 'utils/customHooks';
import { useFetchWidgetData } from '../ctHooks';

export default function SalesRepExceptionClusters_Marico_Rural_VSR({
  url,
  branchesView,
  branchData,
  quartile,
  setPenultimate,
}) {
  const data = useFetchWidgetData({ url, branchesView, branchData, setPenultimate });
  const { salesmanMetrics } = useSettings();
  const cutOffTimeSpent = salesmanMetrics?.cutOffTimeSpent;
  const reportingTime = salesmanMetrics?.reportingTime;

  return (
    <Box height={1} flexDirection="column" display="flex" justifyContent="space-evenly">
      <Box height={3 / 8} pt={2}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          style={{ height: '100%' }}
        >
          {[
            [
              'Absent VSRs',
              data.vsrMetrics.salesmanCount -
                (data.vsrMetrics.reportedCount + (data.vsrMetrics.holidaySalesman || 0)),
              quartile?.salesmanAbsent,
              null,
              true,
              false,
              'No. of VSRs who have not shown any activity for the day',
            ],
            [
              'Weekly off VSRs',
              data.vsrMetrics.holidaySalesman,
              quartile?.holidaySalesman,
              null,
              false,
              false,
              'No. of VSRs who are on weekly off',
            ],
          ].map(renderField(6))}
        </Grid>
      </Box>

      <Box height={3 / 8} pb={2}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          style={{ height: '100%' }}
        >
          {[
            [
              `VSRs reported after ${reportingTime ? reportingTime : 'cut-off'}`,
              data.vsrMetrics.reportedAfterCutOff,
              quartile?.reportedAfterCutOff,
              null,
              true,
              false,
              `No. of VSRs who reported after ${reportingTime ? reportingTime : 'cut-off'}`,
            ],
            [
              `VSRs with < ${cutOffTimeSpent ? cutOffTimeSpent + 'hrs' : 'cut-off'} of Market time`,
              data.vsrMetrics.cutOffTimeCount,
              quartile?.cutOffTimeCount,
              null,
              true,
              false,
              `No. of VSRs who are present and spent less than ${
                cutOffTimeSpent ? cutOffTimeSpent + 'hrs' : 'cut-off'
              } in market`,
            ],
          ].map(renderField(6))}
        </Grid>
      </Box>
      <Box height={1 / 4} pb={2}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          style={{ height: '100%' }}
        >
          {[
            [
              `VSRs without ManDays`,
              data.vsrMetrics.manDayNotAchieved,
              null,
              null,
              true,
              false,
              'Count of VSRs who have not achieved ManDay Score',
            ],
          ].map(renderField(6))}
        </Grid>
      </Box>
    </Box>
  );
}
