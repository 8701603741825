import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { CaptionWithValue } from 'components/TypographyUtils';
import { green, grey, red } from 'controlTower/chartUtils/colors';
import { useColor } from 'controlTower/ctHooks';
import * as moment from 'moment';
import React, { useMemo } from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';
import { format0, formatTime } from 'utils/utils';
import { useFetchWidgetData } from './../ctHooks';

const useStyles = makeStyles((theme) => ({
  topGridItem: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '50%',
    },
  },
}));

const marketTimeChartOption = {
  maintainAspectRatio: false,
  responsive: true,
  legend: {
    display: false,
  },

  plugins: {
    datalabels: {
      color: 'rgb(0,0,0,0.87)',
      font: {
        weight: 400,
      },
      formatter: (value) => (value ? formatTime(value) : ''),
    },
  },

  scales: {
    yAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: 'Average Time Spent per SalesRep',
          padding: 0,
        },
        ticks: {
          beginAtZero: true,
          display: false,
        },
        gridLines: {
          display: false,
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
  },
};

const doughnutChartOptions = {
  maintainAspectRatio: false,
  responsive: true,
  legend: {
    position: 'bottom',
    // display: false,
  },
  title: {
    display: true,
    text: 'Outlet Visit Status',
  },
  plugins: {
    datalabels: {
      color: 'rgb(0,0,0,0.87)',
      font: {
        weight: 400,
      },
      display: (ctx) => ctx.dataset.data[ctx.dataIndex],
    },
  },
};

export default function SalesPerformance({ url, branchesView, branchData, setPenultimate }) {
  const classes = useStyles();
  const data = useFetchWidgetData({ url, branchesView, branchData, setPenultimate });

  const inMarketColor = useColor('in_market_time', data.avgTimes.inMarket / 3600000);
  const inStoreColor = useColor('in_store_time', data.avgTimes.inStore / 3600000);

  const avgTimeChartData = useMemo(
    () => ({
      labels: ['In Market', 'In Store'],
      datasets: [
        {
          label: 'Avg Time',
          data: data
            ? [data.avgTimes.inMarket, data.avgTimes.inStore].map((ms) => ~~(ms / 1000))
            : [0, 0],
          backgroundColor: [inMarketColor.color, inStoreColor.color],
        },
      ],
    }),
    [data, inMarketColor, inStoreColor]
  );

  const storeVisitChartData = useMemo(
    () => ({
      labels: ['Visited', 'Passed By', 'Not Visited'],
      datasets: [
        {
          label: 'Sales',
          data: data ? [data.visited, data.passedBy, data.notVisited] : [0, 0, 0],
          backgroundColor: [green, red, grey],
        },
      ],
    }),
    [data]
  );

  return (
    <Box
      // className={classes.root}
      flexDirection="column"
      display="flex"
      justifyContent="center"
      height={1}
    >
      <Box height={3 / 4} pt={1}>
        <Grid
          container
          style={{ height: '100%' }}
          spacing={1}
          justify="space-around"
          alignItems="center"
        >
          <Grid item xs={12} sm={6} className={classes.topGridItem}>
            <Doughnut data={storeVisitChartData} options={doughnutChartOptions} />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.topGridItem}>
            <Bar data={avgTimeChartData} height={100} options={marketTimeChartOption} />
          </Grid>
        </Grid>
      </Box>
      <Box height={1 / 4} py={1}>
        <Grid container justify="space-around" alignItems="center" style={{ height: '100%' }}>
          <Grid item>
            <CaptionWithValue label="Total SalesRep" value={data.salesmanCount || '--'} />
          </Grid>
          <Grid item>
            <CaptionWithValue
              label="Present SalesRep"
              value={data.salesmenWithOrders ? format0(data.salesmenWithOrders) : '--'}
              raw={data.salesmenWithOrders ? data.salesmenWithOrders : null}
            />
          </Grid>
          <Grid item>
            <CaptionWithValue
              label="Market Entry - Exit"
              value={`${
                data.avgTimes.marketEntry ? moment(data.avgTimes.marketEntry).format('LT') : '0:00'
              } - ${
                data.avgTimes.marketExit ? moment(data.avgTimes.marketExit).format('LT') : '0:00'
              }`}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
