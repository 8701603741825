export const planStates = {
  CREATED: 'created',
  QUEUED: 'queued',
  PROCESSING: 'processing',
  COMPLETED: 'completed',
  FAILED: 'failed',
  CANCELLED: 'cancelled',
  FROZEN: 'frozen',
  UNFREEZE: 'Unfreeze',
};

export const planEdits = {
  ADD_PICKLIST: 'ADD_PICKLIST',
  CHANGE_PICKLIST: 'CHANGE_PICKLIST',
  REARRANGE_ROUTE: 'REARRANGE_ROUTE',
  ASSIGN_RETAILER: 'ASSIGN_RETAILER',
  UNASSIGN_RETAILER: 'UNASSIGN_RETAILER',
  REARRANGE_ROUTE_FULL: 'REARRANGE_ROUTE_FULL',
};

export const vehicleParamsLabel = {
  avgSpeed: {
    label: 'Avg Speed',
    isSmall: true,
  },
  fixedCost: {
    label: 'Fixed Cost',
    isSmall: true,
  },
  fixedCostMaxDistance: {
    label: 'Fixed Cost Max Distance',
  },
  variableCost: {
    label: 'Variable Cost',
    isSmall: true,
  },
  variableCostUnit: {
    label: 'Variable Cost Unit',
    options: ['KM', 'HU', 'Drops'],
  },
  id: { label: 'Id' },
  maxDropPoints: { label: 'Max # of Drops', isSmall: true },
  maxTripTime: { label: 'Max Trip Time(hrs)' },
  maxVolume: { label: 'Max Vol(cc)' },
  maxWeight: { label: 'Max Weight(kg)', isSmall: true },
  name: { label: 'Name', isText: true },
  type: { label: 'Type', isText: true },
  maxTrips: {
    label: 'Max # of Trips',
    isSmall: true,
  },
  contractType: {
    label: 'Contract Type',
    options: ['Own', 'Monthly', 'Adhoc'],
  },
  defaultSpeed: {
    label: 'Default Speed',
    isSmall: true,
  },
  notionalCostFactor: {
    label: 'Notional Cost Factor',
    isSmall: true,
  },
  minUtilization: {
    label: 'Min. Utilization',
    isSmall: true,
  },
  maxHUs: {
    label: 'Max HUs',
  },
  vehicleCount: { label: 'Vehicles Available', isSmall: true },
  volumeLimitRatio: { label: 'Max Utilization(%)', isSmall: true },
  maxDistance: { label: 'Max Distance (KM)' },
  loadingTime: { label: 'Loading+Unloading Time(mins)' },
  serviceTime: { label: 'Service Time per customer(mins)' },
  isSplitCompatible: { label: 'Split Compatible' },
};

export const metrics = {
  AVG_DURATION: 'avgDuration',
  AVG_DISTANCE: 'avgDistane',
};

export const userTypes = {
  SALESMAN: 'sales',
  DB: 'delivery',
};

export const transactionFilters = {
  PENDING: 'Pending',
  PARTIAL: 'Partial',
  PAID: 'Paid',
  SMS_SENT: 'SMS Sent',
  SMS_NOT_SENT: 'SMS Not Sent',
};
