import { Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { blueDarkPh, greenDarkPh, redDarkPh, yellowDarkPh } from 'controlTower/chartUtils/colors';
import DoughnutChart from 'controlTower/chartUtils/DoughnutChart';
import { useFetchWidgetData } from 'controlTower/ctHooks';
import React, { useMemo } from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import { formatCurrencyNum } from 'utils/utils';

const salesChartOption = {
  maintainAspectRatio: false,
  responsive: true,
  legend: {
    display: false,
  },

  plugins: {
    datalabels: {
      color: 'rgb(0,0,0,0.87)',
      font: {
        weight: 400,
      },
      formatter: (value) => (value ? formatCurrencyNum(value) : ''),
    },
  },
  scales: {
    yAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    xAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: 'Sales',
        },
        ticks: {
          beginAtZero: true,
          display: false,
        },
        gridLines: {
          display: false,
        },
      },
    ],
  },
};

export default function PhSupervisor({ url, branchesView, branchData, setPenultimate }) {
  const data = useFetchWidgetData({ url, branchesView, branchData, setPenultimate });
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    visited,
    passedBy,
    total,
    reportedCount,
    salesmanCount,
    productiveCount,
    activeRetailersCount,
    allBeatRetailersCount,
  } = data;
  const doughnuts = [
    { label: 'Salesmen Present', value: reportedCount, total: salesmanCount, cutOffs: [1, 1] },
    { label: 'Call Compliance', value: visited + passedBy, total, cutOffs: [0.8, 0.7] },
    { label: 'Call Productivity', value: productiveCount, total, cutOffs: [0.8, 0.7] },
    {
      label: 'P1M Active Stores',
      value: activeRetailersCount,
      total: allBeatRetailersCount,
      cutOffs: [0.9, 0.8],
    },
    { label: 'IGP', value: data.igp },
    { label: 'DGP 3.0', value: data.dgp },
  ];

  const salesChartData = useMemo(
    () => ({
      labels: ['Today', 'P3M'],
      datasets: [
        {
          data: [data.sales, Math.round(data.ptmAverage)],
          backgroundColor: [
            data.ptmAverage > 0
              ? data.sales > data.ptmAverage
                ? greenDarkPh
                : data.sales > 0.9 * data.ptmAverage
                ? yellowDarkPh
                : redDarkPh
              : greenDarkPh,
            blueDarkPh,
          ],
        },
      ],
    }),
    [data]
  );

  return (
    <Box height={1} flexDirection="column" display="flex" justifyContent="flex-start">
      <Box height={2 / 7} pt={1}>
        <HorizontalBar data={salesChartData} options={salesChartOption} />
      </Box>
      <Box height={2 / 3} display="flex" flexWrap="wrap" flexDirection="row">
        {doughnuts.map((doughnut) =>
          isMobileView ? (
            <Box width={1 / 2} height={1 / 3} key={doughnut.label} p={1}>
              <DoughnutChart {...doughnut} />
            </Box>
          ) : (
            <Box width={1 / 3} height={1 / 2} key={doughnut.label} p={1}>
              <DoughnutChart {...doughnut} />
            </Box>
          )
        )}
      </Box>
    </Box>
  );
}
