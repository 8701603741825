import { Box, Grid } from '@material-ui/core';
import { renderField } from 'components/TypographyUtils';
import React from 'react';
import { useCurrencyFormatter } from 'utils/customHooks';
import { format1, isVal } from 'utils/utils';
import { useFetchWidgetData } from './../ctHooks';

export default function RSPSalesProductivity({
  url,
  branchesView,
  branchData,
  quartile,
  setPenultimate,
}) {
  const data = useFetchWidgetData({ url, branchesView, branchData, setPenultimate });
  const formatCurrency = useCurrencyFormatter();

  return (
    <Box height={1} flexDirection="column" display="flex" justifyContent="space-evenly">
      <Box height={1 / 2} pt={2}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          style={{ height: '100%' }}
        >
          {[
            [
              'Av Bills/RSP',
              format1(data.billsCut / data.salesmenWithOrders),
              quartile?.billsCut,
              data.billsCut / data.salesmenWithOrders,
            ],
            [
              'Av Lines/RSP',
              format1(data.linesCut / data.salesmenWithOrders),
              quartile?.linesCut,
              data.linesCut / data.salesmenWithOrders,
            ],
            ['Av Lines/Bill', format1(data.avgLinesCut), quartile?.avgLinesCut, data.avgLinesCut],
          ].map(renderField(4))}
        </Grid>
      </Box>

      <Box height={1 / 2} pb={2}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          style={{ height: '100%' }}
        >
          {[
            [
              'Order Value',
              data.sales ? formatCurrency(data.sales / (data.salesmenWithOrders ?? 1)) : '--',
              quartile?.sales,
              data.sales,
            ],
            [
              '% Outlet Productivity',
              isVal(data.productivity) ? data.productivity + '%' : '--',
              quartile?.productivity,
              data.productivity,
            ],
          ].map(renderField(6))}
        </Grid>
      </Box>
    </Box>
  );
}
