import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import { formatCurrency0 } from 'utils/utils';
import { useFetchWidgetData } from '../ctHooks';

const columns = [
  {
    label: 'RSP Type',
    align: 'left',
    value: ({ row }) => row.type,
  },
  {
    label: 'Count RSP',
    value: ({ row }) => row.salesmanCount,
  },
  {
    label: 'Total Sub',
    value: ({ row }) => row.allRetailerCount,
  },
  {
    label: 'ECO',
    value: ({ row }) => row.invoiceRetailerCount,
  },
  {
    label: 'Bill Cut',
    value: ({ row }) => row.billsCut,
  },
  {
    label: 'Lines',
    value: ({ row }) => row.linesCut,
  },
  {
    label: 'Target (Lacs)',
    value: ({ row }) => formatCurrency0(row.target),
  },
  {
    label: 'Ach (Lacs)',
    value: ({ row }) => formatCurrency0(row.sales / 100000),
  },
];

export default function RSPPerformance({ url, branchesView, branchData, setPenultimate }) {
  const { mtdInvoiceMetrics } = useFetchWidgetData({
    url,
    branchesView,
    branchData,
    setPenultimate,
  });

  const total = mtdInvoiceMetrics.reduce(
    (acc, cur) => ({
      type: 'Total',
      salesmanCount: acc.salesmanCount + cur.salesmanCount,
      billsCut: acc.billsCut + cur.billsCut,
      linesCut: acc.linesCut + cur.linesCut,
      target: acc.target + cur.target,
      sales: acc.sales + cur.sales,
    }),
    {
      salesmanCount: 0,
      billsCut: 0,
      linesCut: 0,
      target: 0,
      sales: 0,
    }
  );

  return (
    <Box height={1} flexDirection="column" display="flex" justifyContent="space-evenly">
      <Box height={1} py={2}>
        <TableContainer>
          <Table stickyHeader aria-label="Channel Metrics table">
            <TableHead>
              <TableRow>
                {columns.map((column, i) => (
                  <TableCell
                    style={{
                      border: '1px solid black',
                      padding: 1,
                      backgroundColor: '#07162a',
                      color: 'white',
                      wordWrap: 'break-word',
                    }}
                    key={i}
                    align={column.align ?? 'right'}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {mtdInvoiceMetrics
                .sort((a, b) => a.type?.localeCompare(b.type) ?? 1)
                .slice(0, 6)
                .map((row, index) => (
                  <TableRow hover tabIndex={-1} key={index}>
                    {columns.map((column, i) => {
                      const value = column.value({ row });
                      return (
                        <TableCell
                          key={i}
                          style={{
                            border: '1px solid black',
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            padding: 1,
                          }}
                          align={column.align ?? 'right'}
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              <TableRow hover tabIndex={-1} key="total">
                {columns.map((column) => {
                  const value = column.value({ row: total });
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align ?? 'right'}
                      style={{
                        border: '1px solid black',
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        padding: 1,
                      }}
                    >
                      {value}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
