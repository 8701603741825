import React, { useCallback, useEffect } from 'react';
import { useCurrencyFormatter } from 'utils/customHooks';
import { Grid, Box } from '@material-ui/core';
import { CaptionWithValue } from 'components/TypographyUtils';
import { format1, formatTime, isVal } from 'utils/utils';
import { useGDispatch, ga } from 'state/store';
import { metrics } from 'utils/constants';
import { useFetchWidgetData } from './../ctHooks';

export default function DeliveryPlanning({ url, branchesView, branchData, setPenultimate }) {
  const data = useFetchWidgetData({ url, branchesView, branchData, setPenultimate });
  const formatCurrency = useCurrencyFormatter();
  const gDispatch = useGDispatch();

  const renderField = useCallback(
    ([label, value, id = false, raw], i) => (
      <Grid item xs={3} key={i}>
        <CaptionWithValue value={value} label={label} raw={raw} id={id}></CaptionWithValue>
      </Grid>
    ),
    []
  );

  useEffect(() => {
    if (!data) return;
    gDispatch([
      ga.METRICS,
      {
        [metrics.AVG_DURATION]: data.avgPerVehicle.tripTime,
        [metrics.AVG_DISTANCE]: data.avgPerVehicle.tripDistance,
      },
    ]);
  }, [data, gDispatch]);

  if (!data) return null;

  const { avgPerVehicle: avg, drops, value, vehicles, offRoute } = data;
  return (
    <Box height={1} flexDirection="column" display="flex" justifyContent="space-evenly">
      <Box height={3 / 8} pt={2}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          style={{ height: '100%' }}
        >
          {[
            ['# Vehicles', vehicles],
            [
              'Wgt. Util.',
              avg.weightUtilization ? format1(avg.weightUtilization * 100) + ' %' : '-',
              'weight_util',
              avg.weightUtilization * 100,
            ],
            [
              'Vol. Util.',
              avg.volumeUtilization ? format1(avg.volumeUtilization * 100) + ' %' : '-',
              'volume_util',
              avg.volumeUtilization * 100,
            ],
            ['Budget Adh', '--'],
          ].map(renderField)}
        </Grid>
      </Box>

      <Box height={3 / 8} pb={2}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          style={{ height: '100%' }}
        >
          {[
            ['Avg. Drops', avg.drops ? format1(avg.drops) : '--', 'avg_drops', avg.drops],
            [
              'Retry Calls',
              isVal(drops.retry) ? format1(100 * (drops.retry / drops.total)) + ' %' : '--',
              'retry',
              100 * (drops.retry / drops.total),
            ],
            ['Off Route', isVal(offRoute) ? format1(offRoute * 100) + ' %' : '--', 'off_route'],
            [
              'Avg. Duration',
              avg.tripTime ? formatTime(avg.tripTime) : '-',
              'avg_planned_time',
              avg.tripTime ? avg.tripTime / 3600 : null,
            ],
          ].map(renderField)}
        </Grid>
      </Box>

      <Box height={1 / 4} py={1}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          style={{ height: '100%' }}
        >
          {[
            ['Avg. km.', avg.tripDistance ? `${format1(avg.tripDistance / 1000)}km` : '-'],
            ['Avg. Value', formatCurrency(value.total / vehicles)],
            ['Total Value', formatCurrency(value.total)],
            ['Total Calls', drops.total],
          ].map(renderField)}
        </Grid>
      </Box>
    </Box>
  );
}
