import { Box, Grid } from '@material-ui/core';
import { renderField } from 'components/TypographyUtils';
import React from 'react';
import { useCurrencyFormatter } from 'utils/customHooks';
import { format1, isVal } from 'utils/utils';
import { useFetchWidgetData } from './../ctHooks';

export default function SalesProductivity({
  url,
  branchesView,
  branchData,
  quartile,
  setPenultimate,
}) {
  const data = useFetchWidgetData({ url, branchesView, branchData, setPenultimate });
  const formatCurrency = useCurrencyFormatter();

  return (
    <Box height={1} flexDirection="column" display="flex" justifyContent="space-evenly">
      <Box height={3 / 8} pt={2}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          style={{ height: '100%' }}
        >
          {[
            [
              'Av Bills/SalesRep',
              format1(data.billsCut / data.salesmenWithOrders),
              quartile?.billsCut,
              data.billsCut / data.salesmenWithOrders,
            ],
            [
              'Av Lines/SalesRep',
              format1(data.linesCut / data.salesmenWithOrders),
              quartile?.linesCut,
              data.linesCut / data.salesmenWithOrders,
            ],
            ['Av Lines/Bill', format1(data.avgLinesCut), quartile?.avgLinesCut, data.avgLinesCut],
          ].map(renderField(4))}
        </Grid>
      </Box>

      <Box height={3 / 8} pb={2}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          style={{ height: '100%' }}
        >
          {[
            [
              '% Visit Compliance',
              isVal(data.visits) ? data.visits + '%' : '--',
              quartile?.visits,
              data.visits,
            ],
            [
              '% Outlet Productivity',
              isVal(data.productivity) ? data.productivity + '%' : '--',
              quartile?.productivity,
              data.productivity,
            ],
          ].map(renderField(6))}
        </Grid>
      </Box>

      <Box height={1 / 4} py={1}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          style={{ height: '100%' }}
        >
          {[
            [
              'Av Sales/SalesRep',
              isVal(data.sales) ? formatCurrency(data.sales / data.salesmenWithOrders) : '--',
              quartile?.sales,
              data.sales / data.salesmenWithOrders,
            ],
            [
              '# SalesRep Achieved Edge',
              isVal(data.edgeAchievement)
                ? `${data.edgeAchievement} / ${data.salesmenWithOrders}`
                : '--',
              quartile?.edgeAchievement,
              data.edgeAchievement / data.salesmenWithOrders,
            ],
          ].map(renderField(6))}
        </Grid>
      </Box>
    </Box>
  );
}
