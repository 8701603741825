import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import WidgetContainer from 'controlTower/components/WidgetContainer';
import { widgetsMapping } from 'controlTower/ctConstants';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useGState } from 'state/store';
import fetch from 'utils/fetch';
import { byId } from 'utils/utils';
// import { FixedSizeList as List } from 'react-window';
// import AutoSizer from 'react-virtualized-auto-sizer';

const useStyles = makeStyles((theme) => ({
  root: {
    // top: 0,
    height: '100%',
    // bottom: 0,
    position: 'relative',
    width: '100%',
  },
  gridContainer: { position: 'relative' },
  gridItem: {
    position: 'relative',
    height: 400,
    [theme.breakpoints.down('sm')]: {
      height: 650,
    },
  },
  widgetContainer: { position: 'relative', height: '100%' },
}));

export default function BranchesView({ widgetType, id, type }) {
  const classes = useStyles();
  const { date } = useGState((s) => ({ date: s.date }));
  const widget = byId(widgetsMapping, Number(widgetType), 'widgetId');

  const { data: nodesData } = useQuery(
    ['widgets_branches', { url: widget.url, date, id }],
    (_, { url, date, id }) =>
      fetch(`${url}?date=${date}&id=${id}${type ? `&type=${type}` : ''}&separate=true`)
  );

  // for sorting widgets alphabetically
  const dataArr = useMemo(() => {
    if (!nodesData) return null;
    const arr = Array.isArray(nodesData) ? nodesData : nodesData?.data;
    return arr.sort((a, b) => {
      if (a?.name < b?.name) {
        return -1;
      }
      if (a?.name > b?.name) {
        return 1;
      }
      return 0;
    });
  }, [nodesData]);

  const quartile = useMemo(() => calculateQuartiles(dataArr, Number(widgetType)), [
    dataArr,
    widgetType,
  ]);
  if (!dataArr) return null;

  return (
    <div className={classes.root}>
      <Grid container className={classes.gridContainer}>
        {dataArr?.sort()?.map((data, i) => (
          <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={i} className={classes.gridItem}>
            <WidgetContainer
              branchData={data}
              Widget={widget.widget}
              name={widget.name}
              getLeafUrl={widget.getLeafUrl}
              branchesView
              id={id}
              widgetId={widgetType}
              quartile={quartile}
              className={classes.widgetContainer}
              salesmanType={widget.salesmanType}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

function calculateQuartiles(data, widgetId) {
  if (!data) return;
  let qtl = {};
  if (!quartileMap[widgetId]) return;
  Object.entries(quartileMap[widgetId]).forEach(([key, value]) => {
    qtl[key] = quartile(data.map(value));
  });
  return qtl;
}

function quartile(arr) {
  const [p25, p50, p75] = [percentile(arr, 0.25), percentile(arr, 0.5), percentile(arr, 0.75)];
  if (p25 === undefined || isNaN(p25)) return undefined;
  if (p25 === p50 && p50 === p75) return undefined;
  return [p25, p50, p75];
}

const percentile = (arr, q) => {
  const sorted = arr.sort((a, b) => a - b);
  const pos = (sorted.length - 1) * q;
  const base = Math.floor(pos);
  const rest = pos - base;
  if (sorted[base + 1] !== undefined) {
    return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
  } else {
    return sorted[base];
  }
};

const quartileMap = {
  // Sales Productivity
  1: {
    billsCut: (d) => d.billsCut / d.salesmenWithOrders,
    linesCut: (d) => d.linesCut / d.salesmenWithOrders,
    avgLinesCut: (d) => d.avgLinesCut,
    visits: (d) => d.visits,
    productivity: (d) => d.productivity,
    sales: (d) => d.sales / d.salesmenWithOrders,
    achievedAssortment: (d) => d.achievedAssortment / d.targetAssortment,
    edgeAchievement: (d) => d.edgeAchievement / d.salesmenWithOrders,
  },
  // Sales Rep Exception Clusters
  3: {
    salesmanAbsent: (d) => d.salesmanCount - (d.salesmenWithOrders + (d.holidaySalesman || 0)),
    reportedCount: (d) => d.reportedCount,
    reportedAfterCutOff: (d) => d.reportedAfterCutOff,
    cutOffTimeCount: (d) => d.cutOffTimeCount,
    cutOffBillsCount: (d) => d.cutOffBillsCount,
    cutOffLinesCount: (d) => d.cutOffLinesCount,
    holidaySalesman: (d) => d.holidaySalesman,
  },
  // 'Ph Supervisor': {
  //   salesmanPresent: (d) => d.reportedCount / d.salesmanCount,
  //   calCompliance: (d) => (d.visited + d.passedBy) / d.total,
  //   callProductivity: (d) => d.productiveCount / d.total,
  //   p1mActiveStores: (d) => d.activeRetailersCount / d.allBeatRetailersCount,
  //   igp: (d) => d.cutOffBillsCount,
  //   dgp: (d) => d.cutOffLinesCount,
  //   sales: (d) => d.holidaySalesman,
  // },
  // Adherence Metrics
  4: {
    plannedBeatCompliance: (d) => d.plannedBeatCompliance,
    awayOrders: (d) => 100 - d.awayOrders,
    zeroSalesBeatsOrders: (d) =>
      d.salesmenWithOrders -
      (d.oneSalesBeatsOrders + d.twoSalesBeatOrders + d.twoPlusSalesBeatOrders),
    oneSalesBeatsOrders: (d) => d.oneSalesBeatsOrders,
    twoSalesBeatOrders: (d) => d.twoSalesBeatOrders,
    twoPlusSalesBeatOrders: (d) => d.twoPlusSalesBeatOrders,
  },
  // Sales Productivity (Billed)
  5: {
    billsCut: (d) => d.invoices?.billsCut / d.salesmenWithOrders,
    linesCut: (d) => d.invoices?.linesCut / d.salesmenWithOrders,
    linesByBills: (d) => d.invoices?.linesCut / d.invoices?.billsCut,
    sales: (d) => d.invoices?.sales / d.salesmenWithOrders,
    edgeAchievement: (d) => d.invoices?.edgeAchievement / d.salesmenWithOrders,
  },
  // Sales Rep Score Card
  6: {
    beatCompliantScore: (d) => d.beatCompliantScore,
    marketEntryScore: (d) => d.marketEntryScore,
    timeInMarketScore: (d) => d.itimeInMarketScore,
    billsAndOrdersScore: (d) => d.billsAndOrdersScore,
    edgeScore: (d) => d.edgeScore,
    assortmentAdherenceScore: (d) => d.assortmentAdherenceScore,
    finalExecutionScore: (d) => d.finalExecutionScore,
  },
};
