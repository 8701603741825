import React, { useMemo } from 'react';
import { useFetchWidgetData } from './../ctHooks';
import { Box } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import { green, yellow, blue, red, brown } from 'controlTower/chartUtils/colors';
import { formatCurrencyNum } from 'utils/utils';

const chartOptions = {
  maintainAspectRatio: false,

  responsive: true,
  tooltips: {
    mode: 'label',
    callbacks: {
      label: function (tooltipItem, data) {
        let label = data.datasets[tooltipItem.datasetIndex].label || '';
        if (label) {
          label += ': ';
        }
        label +=
          tooltipItem.datasetIndex === 0
            ? formatCurrencyNum(tooltipItem.yLabel)
            : tooltipItem.yLabel;
        return label;
      },
    },
  },
  elements: {
    line: {
      fill: false,
    },
  },
  scales: {
    xAxes: [
      {
        display: true,
        gridLines: {
          display: false,
        },
        labels: ['< 7', '7 - 15', '16 - 30', '> 30'],
      },
    ],
    yAxes: [
      {
        type: 'linear',
        display: true,
        position: 'left',
        id: 'retailers',
        ticks: {
          beginAtZero: true,
        },
        gridLines: {
          display: false,
        },
        labels: {
          show: true,
        },
      },
      {
        type: 'linear',
        display: true,
        position: 'right',
        id: 'value',
        ticks: {
          beginAtZero: true,
          callback: (value, _, values) =>
            values[0] > 1000000 ? value / 1000000 + ' M' : value / 1000 + ' K',
        },
        gridLines: {
          display: false,
        },
        labels: {
          show: true,
        },
      },
    ],
  },
};

export default function ARAging({ url, branchesView, branchData, setPenultimate }) {
  const data = useFetchWidgetData({ url, branchesView, branchData, setPenultimate });

  const chartData = useMemo(
    () => ({
      // labels: ['< 7', '7 - 15', '16 - 30', '> 30'],
      datasets: [
        {
          label: 'Value',
          type: 'line',
          fill: false,

          data: data
            ? [data['<7'], data['7-15'], data['16-30'], data['>30']].map((o) => o.value)
            : [0, 0, 0, 0],
          backgroundColor: brown,
          borderColor: brown,
          datalabels: {
            display: false,
          },
          yAxisID: 'value',
        },
        {
          label: '#Customers',
          type: 'bar',
          data: data
            ? [data['<7'], data['7-15'], data['16-30'], data['>30']].map((o) => o.retailers)
            : [0, 0, 0, 0],
          backgroundColor: [green, yellow, blue, red],
          yAxisID: 'retailers',
        },
      ],
    }),
    [data]
  );

  if (!data) return null;

  return (
    <Box height={1}>
      <Bar data={chartData} options={chartOptions}></Bar>
    </Box>
  );
}
