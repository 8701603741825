import React, { Suspense } from 'react';
import Layout from 'components/Layout';
import { LinearProgress } from '@material-ui/core';
import { Router } from '@reach/router';
import BranchesView from 'controlTower/components/BranchesView';
const isSSR = typeof window === 'undefined';

export default (props) => (
  <Layout {...props} hideNodeSelector>
    {!isSSR && (
      <Suspense fallback={<LinearProgress color="secondary" />}>
        <Router style={{ height: '100%', position: 'relative' }}>
          <BranchesView
            path="/ct/Branches/:widgetType/:id/:type"
            style={{ height: '100%', position: 'relative' }}
          />
        </Router>
      </Suspense>
    )}
  </Layout>
);
