import { Box, Grid } from '@material-ui/core';
import { renderField } from 'components/TypographyUtils';
import React from 'react';
import { useCurrencyFormatter } from 'utils/customHooks';
import { format1, isVal } from 'utils/utils';
import { useFetchWidgetData } from '../ctHooks';

export default function SalesProductivityBilled_Marico({
  url,
  branchesView,
  branchData,
  quartile: q,
  setPenultimate,
}) {
  const rawData = useFetchWidgetData({ url, branchesView, branchData, setPenultimate });
  const data = rawData?.invoices;
  const formatCurrency = useCurrencyFormatter();

  return (
    <Box height={1} flexDirection="column" display="flex" justifyContent="space-evenly">
      <Box height={3 / 8} pt={2}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          style={{ height: '100%' }}
        >
          {[
            [
              'Avg Index Sales/DSR',
              isVal(data?.sales) ? formatCurrency(data?.sales / data.salesmenWithInvoices) : '--',
              q?.sales,
              data?.sales / data.salesmenWithInvoices,
              false,
              false,
              'Avg Index Sales/DSR (with invoices.for the day)',
            ],
            [
              'Avg PC/DSR',
              format1(data?.invoiceRetailersCount / data.salesmenWithInvoices),
              null,
              null,
              false,
              false,
              'Avg of ratio of Productive Calls to DSRs present (with invoices for the day)',
            ],
          ].map(renderField(6))}
        </Grid>
      </Box>

      <Box height={3 / 8} pb={2}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          style={{ height: '100%' }}
        >
          {[
            [
              'Outlet Productivity %',
              isVal(data?.invoiceRetailersCount)
                ? format1(rawData.total ? (100 * data?.invoiceRetailersCount) / rawData.total : 0) +
                  '%'
                : '--',
              null,
              null,
              false,
              false,
              '% of outlets in Designed Beats with Invoices for the day.',
            ],
            [
              'Total BPD/DSR',
              data?.totalInvoiceBrands
                ? format1(data?.totalInvoiceBrands / data.salesmenWithInvoices)
                : '--',
              null,
              null,
              false,
              false,
              'Avg no. of distinct count of Brands per DSR with invoices.',
            ],
          ].map(renderField(6))}
        </Grid>
      </Box>
      <Box height={1 / 4} py={1}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          style={{ height: '100%' }}
        >
          {[
            [
              'DSR with invoices',
              !data.salesmenWithInvoices && data.salesmenWithInvoices !== 0
                ? '--'
                : data.salesmenWithInvoices,
              null,
              null,
              false,
              false,
              'Count of DSRs who have PDA invoices',
            ],
          ].map(renderField(6))}
        </Grid>
      </Box>
    </Box>
  );
}
