import { Box, Grid } from '@material-ui/core';
import { renderField } from 'components/TypographyUtils';
import React from 'react';
import { useCurrencyFormatter } from 'utils/customHooks';
import { format1, isVal } from 'utils/utils';
import { useFetchWidgetData } from './../ctHooks';

export default function RSPSalesProductivityBilled({
  url,
  branchesView,
  branchData,
  quartile: q,
  setPenultimate,
}) {
  const rawData = useFetchWidgetData({ url, branchesView, branchData, setPenultimate });
  const data = rawData?.invoices;
  const formatCurrency = useCurrencyFormatter();

  return (
    <Box height={1} flexDirection="column" display="flex" justifyContent="space-evenly">
      <Box height={1 / 2} pt={2}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          style={{ height: '100%' }}
        >
          {[
            [
              'Av Bills/RSP',
              format1(data?.billsCut / rawData.salesmenWithOrders),
              q?.billsCut,
              data?.billsCut / rawData.salesmenWithOrders,
            ],
            [
              'Av Line/RSP',
              format1(data?.linesCut / rawData.salesmenWithOrders),
              q?.linesCut,
              data?.linesCut / rawData.salesmenWithOrders,
            ],
            [
              'Av Lines/Bill',
              format1(data?.linesCut / data?.billsCut),
              q?.linesByBills,
              data?.linesCut / data?.billsCut,
            ],
          ].map(renderField(4))}
        </Grid>
      </Box>

      <Box height={1 / 2} pb={2}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          style={{ height: '100%' }}
        >
          {[
            [
              'Order Value',
              isVal(data?.sales)
                ? formatCurrency(data.sales / (rawData.salesmenWithOrders ?? 1))
                : '--',
            ],
            [
              '% Outlet Productivity',
              isVal(data?.invoiceRetailersCount)
                ? format1(rawData.total ? (100 * data?.invoiceRetailersCount) / rawData.total : 0) +
                  '%'
                : '--',
            ],
          ].map(renderField(6))}
        </Grid>
      </Box>
    </Box>
  );
}
