// import redMui from '@material-ui/core/colors/red';
// import greenMui from '@material-ui/core/colors/green';
// import blueMui from '@material-ui/core/colors/blue';
// import yellowMui from '@material-ui/core/colors/yellow';
// import greyMui from '@material-ui/core/colors/grey';

// export const red = redMui[300];
// export const green = greenMui[300];
// export const blue = blueMui[300];
// export const yellow = yellowMui[300];
// export const grey = greyMui[300];

export const red = 'rgba(255, 99, 132, 0.6)';
export const green = 'rgba(75, 192, 80, 0.6)';
export const blue = 'rgba(54, 162, 235, 0.6)';
export const yellow = 'rgba(255, 206, 86, 0.6)';
export const amber = 'rgba(239,108,0, 0.6)';
export const brown = '#EC932F';
export const grey = 'rgba(0, 0, 0, 0.3)';
export const white = '#fff';

export const redM = [255, 99, 132, 255];
export const greenM = [75, 192, 192, 255];
export const blueM = [54, 162, 235, 255];
export const yellowM = [255, 193, 7, 255];

export const redMDark = [255, 0, 0, 255];
// export const greenMDark = [27, 94, 32, 255];
export const greenMDark = [76, 175, 80, 255];
export const yellowMDark = [255, 214, 0, 255];
export const blueMDark = [0, 255, 255, 255];

export const blackM = [0, 0, 0, 255];
export const whiteM = [255, 255, 255, 100];

export const redDark = 'rgba(255, 99, 132, 1)';
export const greenDark = 'rgba(75, 192, 192, 1)';
export const blueDark = 'rgba(54, 162, 235, 1)';
export const yellowDark = 'rgba(255, 206, 86, 1)';

export const redLight = 'rgba(255, 99, 132, 0.5)';
export const greenLight = 'rgba(75, 192, 192, 0.5)';
export const blueLight = 'rgba(54, 162, 235, 0.5)';
export const yellowLight = 'rgba(255, 206, 86, 0.5)';

export const redDarkPh = '#FF2D55';
export const greenDarkPh = '#34C759';
export const blueDarkPh = '#007AFF';
export const yellowDarkPh = '#FF9500';
