import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { CaptionWithValue } from 'components/TypographyUtils';
import { amber, green, red } from 'controlTower/chartUtils/colors';
import { useColor } from 'controlTower/ctHooks';
import * as moment from 'moment';
import React, { useMemo } from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';
import { format0, formatTime } from 'utils/utils';
import { useFetchWidgetData } from '../ctHooks';

const useStyles = makeStyles((theme) => ({
  topGridItem: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '50%',
    },
  },
}));

const marketTimeChartOption = {
  maintainAspectRatio: false,
  responsive: true,
  legend: {
    display: false,
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        return data?.datasets[0]?.label?.[Number(tooltipItem?.index)][0];
      },
      afterBody: function (tooltipItem, data) {
        return data?.datasets[0]?.label?.[Number(tooltipItem[0]?.index)]?.slice(1);
      },
    },
  },

  plugins: {
    datalabels: {
      color: 'rgb(0,0,0,0.87)',
      font: {
        weight: 400,
      },
      formatter: (value) => (value ? formatTime(value) : ''),
    },
  },

  scales: {
    yAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: 'Average Time Spent per DSRs',
          padding: 0,
        },
        ticks: {
          beginAtZero: true,
          display: false,
        },
        gridLines: {
          display: false,
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
  },
};
const doughnutChartOptions = {
  maintainAspectRatio: false,
  responsive: true,
  legend: {
    position: 'bottom',
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        return data?.datasets[0]?.label?.[Number(tooltipItem?.index)][0];
      },
      afterBody: function (tooltipItem, data) {
        var multistringText = data?.datasets[0]?.label?.[Number(tooltipItem[0]?.index)]?.slice(1);
        return multistringText;
      },
    },
  },
  title: {
    display: true,
    text: 'Outlet Visit Status',
  },
  plugins: {
    datalabels: {
      color: 'rgb(0,0,0,0.87)',
      font: {
        weight: 400,
      },
      display: (ctx) => ctx.dataset.data[ctx.dataIndex],
    },
  },
};

export default function SalesPerformance_Marico({ url, branchesView, branchData, setPenultimate }) {
  const classes = useStyles();
  const data = useFetchWidgetData({ url, branchesView, branchData, setPenultimate });

  const inMarketColor = useColor('in_market_time', data.avgTimes.inMarket / 3600000);
  const inStoreColor = useColor('in_store_time', data.avgTimes.inStore / 3600000);

  const avgTimeChartData = useMemo(
    () => ({
      labels: ['In Market', 'In Store'],
      datasets: [
        {
          label: [
            ['Avg time of DSRs', 'spending in market.'],
            ['Avg time of DSRs', 'spending at store.'],
          ],
          data: data
            ? [data.avgTimes.inMarket, data.avgTimes.inStore].map((ms) => ~~(ms / 1000))
            : [0, 0],
          backgroundColor: [inMarketColor.color, inStoreColor.color],
        },
      ],
    }),
    [data, inMarketColor, inStoreColor]
  );

  const {
    beatOrderRetailersCount,
    beatAwayOrderRetailersCount,
    beatFailedOrderRetailersCount,
    beatAwayFailedOrderRetailersCount,
    beatRetailersCount,
  } = data;

  const storeVisitChartData = useMemo(
    () => ({
      labels: ['Visits - geo compliant', 'Visits - non geo compliant', 'Not Visited'],
      datasets: [
        {
          label: [
            ['No of beat outlets which', 'have orders/failedorders', '& visited.'],
            ['No of beat outlets which', 'have orders/failedorders', '& not visited.'],
            ['No. of outlets which are', 'not visited and do not', 'have orders for the day.'],
          ],
          data: data
            ? [
                beatOrderRetailersCount -
                  beatAwayOrderRetailersCount +
                  (beatFailedOrderRetailersCount - beatAwayFailedOrderRetailersCount),
                beatAwayOrderRetailersCount + beatAwayFailedOrderRetailersCount,
                beatRetailersCount - beatOrderRetailersCount - beatFailedOrderRetailersCount,
              ]
            : [0, 0, 0],
          backgroundColor: [green, amber, red],
        },
      ],
    }),
    [
      data,
      beatOrderRetailersCount,
      beatAwayOrderRetailersCount,
      beatFailedOrderRetailersCount,
      beatAwayFailedOrderRetailersCount,
      beatRetailersCount,
    ]
  );

  return (
    <Box
      // className={classes.root}
      flexDirection="column"
      display="flex"
      justifyContent="center"
      height={1}
    >
      <Box height={3 / 4} pt={1}>
        <Grid
          container
          style={{ height: '100%' }}
          spacing={1}
          justify="space-around"
          alignItems="center"
        >
          <Grid item xs={12} sm={6} className={classes.topGridItem}>
            <Doughnut data={storeVisitChartData} options={doughnutChartOptions} />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.topGridItem}>
            <Bar data={avgTimeChartData} height={100} options={marketTimeChartOption} />
          </Grid>
        </Grid>
      </Box>
      <Box height={1 / 4} py={1}>
        <Grid container justify="space-around" alignItems="center" style={{ height: '100%' }}>
          <Grid item>
            <CaptionWithValue
              label="DSRs Present / Universe"
              value={`${data.salesmenWithOrders ? format0(data.salesmenWithOrders) : '--'} / ${
                data.salesmanCount || '--'
              }`}
              raw={data.salesmenWithOrders ? data.salesmenWithOrders : null}
              tooltip="Count of DSR who have done any activity on SFA."
            />
          </Grid>
          <Grid item>
            <CaptionWithValue
              label="Market Entry - Exit Time"
              value={`${
                data.avgTimes.marketEntry ? moment(data.avgTimes.marketEntry).format('LT') : '0:00'
              } - ${
                data.avgTimes.marketExit ? moment(data.avgTimes.marketExit).format('LT') : '0:00'
              }`}
              tooltip="Avg entry - exit time of all the PSR entering/exiting the market."
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
