import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useCurrencyFormatter } from 'utils/customHooks';
import { Grid, Box } from '@material-ui/core';
import { CaptionWithValue } from 'components/TypographyUtils';
import { Doughnut } from 'react-chartjs-2';
import { format0, format2 } from 'utils/utils';
import { green, yellow, red, grey } from 'controlTower/chartUtils/colors';
import { useFetchWidgetData } from './../ctHooks';
// import 'chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes';
// import { Trek6 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.office';

const useStyles = makeStyles((theme) => ({
  topGridItem: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '50%',
    },
  },
}));

const serviceChartOptions = {
  maintainAspectRatio: false,
  responsive: true,
  legend: {
    position: 'bottom',
  },
  title: {
    text: 'Service level by Value',
    position: 'top',
    display: true,
  },
  plugins: {
    datalabels: {
      color: 'rgb(0,0,0,0.87)',
      font: {
        weight: 400,
      },
      formatter: (val, ctx) => {
        return (100 * val) / ctx.dataset.data.reduce((a, b) => a + b) > 3
          ? format0((100 * val) / ctx.dataset.data.reduce((a, b) => a + b)) + '%'
          : '';
      },
    },
  },
};

const notDeliveredChartOptions = {
  maintainAspectRatio: false,
  responsive: true,
  legend: {
    position: 'bottom',
    labels: {
      fontSize: 10,
    },
  },
  title: {
    text: 'Not Delivered Breakdown',
    position: 'top',
    display: true,
  },
  plugins: {
    datalabels: {
      color: 'rgb(0,0,0,0.87)',
      font: {
        weight: 400,
      },
      formatter: (val, ctx) => {
        return (100 * val) / ctx.dataset.data.reduce((a, b) => a + b) > 3
          ? format0((100 * val) / ctx.dataset.data.reduce((a, b) => a + b)) + '%'
          : '';
      },
    },
    // colorschemes: {
    //   scheme: Trek6,
    //   fillAlpha: 0.1,
    //   reverse: true,
    // },
  },
};

export default function DeliveryLeadTime({ url, branchesView, branchData, setPenultimate }) {
  const classes = useStyles();
  const data = useFetchWidgetData({ url, branchesView, branchData, setPenultimate });
  const formatCurrency = useCurrencyFormatter();

  const dPlusChartData = useMemo(
    () => ({
      labels: ['D+1', 'D+2', '( D+2 )+', 'Not Delivered'],
      datasets: [
        {
          label: 'By Value',
          data: data
            ? [
                data.deliveryByDay.dPlus1.value,
                data.deliveryByDay.dPlus2.value,
                data.deliveryByDay.dPlus.value,
                data.deliveryByDay.notDelivered.value,
              ]
            : [0, 0, 0, 0],
          backgroundColor: [green, yellow, red, grey],
        },
      ],
    }),
    [data]
  );

  const notDeliveredChartData = useMemo(
    () => ({
      labels: ['Not Assigned', 'Out For Delivery', 'Retry', 'Return', 'No Input'],
      datasets: [
        {
          label: 'By Value',
          data: data
            ? [
                data.notDeliveredBreakdown.notAssigned.value,
                data.notDeliveredBreakdown.outForDelivery.value,
                data.notDeliveredBreakdown.retry.value,
                data.notDeliveredBreakdown.srn.value,
                data.notDeliveredBreakdown.noInput.value,
              ]
            : [0, 0, 0, 0],
          backgroundColor: [
            'rgba(75, 192, 192, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 99, 132, 0.6)',
            'rgba(0, 0, 0, 0.3)',
          ],
        },
      ],
    }),
    [data]
  );

  if (!data) return null;

  const { total, notDelivered, leadTimeByInvoices } = data;

  return (
    <Box
      // className={classes.root}
      height={1}
      flexDirection="column"
      display="flex"
      justifyContent="space-around"
    >
      <Box height={3 / 4} pt={1}>
        <Grid
          container
          direction="row"
          justify="space-around"
          spacing={1}
          alignItems="center"
          style={{ height: '100%' }}
        >
          <Grid item xs={12} sm={6} className={classes.topGridItem}>
            <Doughnut data={dPlusChartData} options={serviceChartOptions} />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.topGridItem}>
            <Doughnut data={notDeliveredChartData} options={notDeliveredChartOptions} />
          </Grid>
        </Grid>
      </Box>

      <Box height={1 / 4} py={1}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          style={{ height: '100%' }}
        >
          <Grid item>
            <CaptionWithValue
              value={leadTimeByInvoices ? format2(leadTimeByInvoices) : '-'}
              label="Lead Time"
              id="lead_time"
            ></CaptionWithValue>
          </Grid>
          <Grid item>
            <CaptionWithValue
              value={`${formatCurrency(total.value)}/${total.retailers}`}
              label="Total Value/Retlrs."
            ></CaptionWithValue>
          </Grid>
          <Grid item>
            <CaptionWithValue
              value={`${formatCurrency(notDelivered.value)}/${notDelivered.retailers}`}
              label="Not Delivered/Retlrs."
            ></CaptionWithValue>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
