import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box } from '@material-ui/core';
import { HorizontalBar, Doughnut } from 'react-chartjs-2';
import { CaptionWithValue } from 'components/TypographyUtils';
import { format1, isVal } from 'utils/utils';
import { green, red, grey } from 'controlTower/chartUtils/colors';
import { useFetchWidgetData } from './../ctHooks';

const useStyles = makeStyles((theme) => ({
  topGridItem: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '50%',
    },
  },
}));

const failureCompliantOptions = {
  maintainAspectRatio: false,
  responsive: true,
  legend: {
    position: 'bottom',
    labels: {
      filter: (item) => !item.text.includes('Not Aplicable'),
    },
  },
  title: {
    display: true,
    text: 'Failed',
  },
  scales: {
    xAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
          display: false,
        },
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
        gridLines: {
          display: false,
        },
      },
    ],
  },
};

const deliveryCompliantOptions = {
  maintainAspectRatio: false,
  responsive: true,
  legend: {
    position: 'bottom',
  },
  title: {
    display: true,
    text: 'Delivered',
  },
  plugins: {
    datalabels: {
      color: 'rgb(0,0,0,0.87)',
      font: {
        weight: 400,
      },
      display: (ctx) => ctx.dataset.data[ctx.dataIndex],
    },
  },
};

export default function DeliveryCompliance({ url, branchesView, branchData, setPenultimate }) {
  const classes = useStyles();
  const data = useFetchWidgetData({ url, branchesView, branchData, setPenultimate });

  if (data) {
    data.failures = data.failures.sort((a, b) =>
      isVal(a.compliant) ? (isVal(b.compliant) ? 0 : -1) : 1
    );
  }

  const deliveryComplianceData = useMemo(
    () => ({
      labels: ['Compliant', 'Non-Compliant'],
      datasets: [
        {
          data: data ? [data.deliveredCompliant, data.delivered - data.deliveredCompliant] : [0, 0],
          backgroundColor: [green, red],
        },
      ],
    }),
    [data]
  );

  const failureCompliant = useMemo(
    () => ({
      labels: data.failures.map((f) => f.reason),
      datasets: [
        {
          label: 'Compliant',
          data: data.failures.length ? data.failures.map((f) => f.compliant) : [],
          backgroundColor: green,
        },
        {
          label: 'Non-Compliant',
          data: data.failures.length ? data.failures.map((f) => f.count - f.compliant) : [],
          backgroundColor: red,
        },
        {
          label: 'Not Aplicable',
          data: data.failures.length
            ? data.failures.map((f) => (isVal(f.compliant) ? null : f.count))
            : [],
          backgroundColor: grey,
        },
      ],
    }),
    [data]
  );

  const { delivered, deliveredCompliant, failures } = data;
  const failureCompliantSums = failures.length
    ? failures
        .filter((f) => isVal(f.compliant))
        .reduce((a, f) => ({ compliant: a.compliant + f.compliant, count: a.count + f.count }), {
          compliant: 0,
          count: 0,
        })
    : null;

  return (
    <Box flexDirection="column" display="flex" justifyContent="center" height={1}>
      <Box height={3 / 4} pt={1}>
        <Grid
          container
          style={{ height: '100%' }}
          spacing={1}
          justify="space-around"
          alignItems="center"
        >
          <Grid item xs={12} sm={6} className={classes.topGridItem}>
            <Doughnut data={deliveryComplianceData} options={deliveryCompliantOptions} />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.topGridItem}>
            <HorizontalBar data={failureCompliant} options={failureCompliantOptions} />
          </Grid>
        </Grid>
      </Box>
      <Box height={1 / 4} py={1}>
        <Grid container justify="space-around" alignItems="center" style={{ height: '100%' }}>
          <Grid item>
            <CaptionWithValue
              label="Delivery Compliance"
              value={
                isVal(deliveredCompliant)
                  ? `${format1((100 * deliveredCompliant) / delivered)} %`
                  : '-'
              }
            />
          </Grid>
          <Grid item>
            <CaptionWithValue
              label="Failure Compliance"
              value={
                failureCompliantSums
                  ? `${format1(
                      (100 * failureCompliantSums.compliant) / failureCompliantSums.count
                    )} %`
                  : '-'
              }
            />
          </Grid>
          <Grid item>
            <CaptionWithValue
              label="Total Compliance"
              value={
                isVal(deliveredCompliant)
                  ? `${format1(
                      (100 *
                        (deliveredCompliant +
                          (failureCompliantSums ? failureCompliantSums.compliant : 0))) /
                        (delivered + (failureCompliantSums ? failureCompliantSums.count : 0))
                    )} %`
                  : '-'
              }
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
