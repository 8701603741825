import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { useFetchWidgetData } from './../ctHooks';
import { format1, isVal } from 'utils/utils';
import { renderField } from 'components/TypographyUtils';

export default function AdherenceMetrics({
  url,
  branchesView,
  branchData,
  quartile: q,
  setPenultimate,
}) {
  const data = useFetchWidgetData({ url, branchesView, branchData, setPenultimate });

  return (
    <Box height={1} flexDirection="column" display="flex" justifyContent="space-evenly">
      <Box height={3 / 8} pt={2}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          style={{ height: '100%' }}
        >
          {[
            [
              'Beat Compliant SalesRep',
              `${data.plannedBeatCompliance} (${format1(
                (100 * data.plannedBeatCompliance) / data.salesmenWithOrders
              )}%)`,
              q?.plannedBeatCompliance,
              data.plannedBeatCompliance,
            ],
            [
              'Outlet Location Compliant orders',
              isVal(data.awayOrders) ? 100 - data.awayOrders + '%' : '--',
              q?.awayOrders,
              100 - data.awayOrders,
            ],
          ].map(renderField(6))}
        </Grid>
      </Box>
      <Box height={3 / 8} pb={2}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          style={{ height: '100%' }}
        >
          {[
            [
              'SalesRep 0 Beat Orders',
              data.salesmenWithOrders -
                (data.oneSalesBeatsOrders + data.twoSalesBeatOrders + data.twoPlusSalesBeatOrders),
              q?.zeroSalesBeatsOrders,
              null,
              true,
            ],
            ['Sales Rep 1 Beat Orders', data.oneSalesBeatsOrders, q?.oneSalesBeatsOrders],
          ].map(renderField(6))}
        </Grid>
      </Box>
      <Box height={1 / 4} py={1}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          style={{ height: '100%' }}
        >
          {[
            ['SalesRep 2 Beat Orders', data.twoSalesBeatOrders, q?.twoSalesBeatOrders, null, true],
            [
              'SalesRep 2+ Beat Orders',
              data.twoPlusSalesBeatOrders,
              q?.twoPlusSalesBeatOrders,
              null,
              true,
            ],
          ].map(renderField(6))}
        </Grid>
      </Box>
    </Box>
  );
}
