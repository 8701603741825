import { Box, Grid } from '@material-ui/core';
import { renderField } from 'components/TypographyUtils';
import React from 'react';
import { useSettings } from 'utils/customHooks';
import { useFetchWidgetData } from './../ctHooks';

export default function RSPSalesRepExceptionClusters({
  url,
  branchesView,
  branchData,
  quartile,
  setPenultimate,
}) {
  const data = useFetchWidgetData({ url, branchesView, branchData, setPenultimate });
  const { salesmanMetrics } = useSettings();
  const cutOffTimeSpent = salesmanMetrics?.cutOffTimeSpent;
  const cutOffBills = salesmanMetrics?.cutOffBills;
  const cutOffLines = salesmanMetrics?.cutOffLines;
  const reportingTime = salesmanMetrics?.reportingTime;

  return (
    <Box height={1} flexDirection="column" display="flex" justifyContent="space-evenly">
      <Box height={3 / 8} pt={2}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          style={{ height: '100%' }}
        >
          {[
            [
              'SalesRep Absent',
              data.salesmanCount - (data.salesmenWithOrders + (data.holidaySalesman || 0)),
              quartile?.salesmanAbsent,
              null,
              true,
            ],

            ['SalesRep Reported', data.reportedCount, quartile?.reportedCount],
          ].map(renderField(6))}
        </Grid>
      </Box>

      <Box height={3 / 8} pb={2}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          style={{ height: '100%' }}
        >
          {[
            [
              `SalesRep Reported after ${reportingTime ? reportingTime : 'cut-off'}`,
              data.reportedAfterCutOff,
              quartile?.reportedAfterCutOff,
              null,
              true,
            ],
            [
              `SalesRep Time Spent < ${cutOffTimeSpent ? cutOffTimeSpent + 'hrs' : 'cut-off'}`,
              data.cutOffTimeCount,
              quartile?.cutOffTimeCount,
              null,
              true,
            ],
          ].map(renderField(6))}
        </Grid>
      </Box>

      <Box height={1 / 4} py={1}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          style={{ height: '100%' }}
        >
          {[
            [
              `SalesRep Bills < ${cutOffBills ? cutOffBills : 'cut-off'}`,
              data.cutOffBillsCount,
              quartile?.cutOffBillsCount,
              null,
              true,
            ],
            [
              `SalesRep Lines < ${cutOffLines ? cutOffLines : 'cut-off'}`,
              data.cutOffLinesCount,
              quartile?.cutOffLinesCount,
              null,
              true,
            ],
          ].map(renderField(6))}
        </Grid>
      </Box>
    </Box>
  );
}
