import React, { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { Box } from '@material-ui/core';
import { useFetchWidgetData } from './../ctHooks';
import { blue } from './../chartUtils/colors';

const hourlySalesChartOptions = {
  maintainAspectRatio: false,
  responsive: true,
  legend: {
    display: false,
  },
  title: {
    display: true,
    text: '',
  },
  plugins: {
    datalabels: {
      align: 'end',
      anchor: 'end',
      color: 'rgb(0,0,0,0.87)',
      font: {
        weight: 400,
      },
      display: true,
      // formatter: (value, ctx) => {
      //   if (!value || !ctx.dataset.data[0]) return '';
      //   return format2((100 * value) / ctx.dataset.data[0]) + ' %';
      // },
    },
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
        gridLines: {
          display: false,
        },
      },
    ],
  },
};

export default function HourlySales({ url, branchesView, branchData, setPenultimate }) {
  const rawData = useFetchWidgetData({ url, branchesView, branchData, setPenultimate });
  const data = rawData?.hourlyOrders || [];

  const chartData = useMemo(
    () => ({
      labels: ['<10', '10-11', '11-12', '12-1', '1-2', '2-3', '3-4', '4-5', '5-6', '>6'],
      datasets: [
        {
          label: 'Hourly Sales',
          data: [
            data[0] +
              data[1] +
              data[2] +
              data[3] +
              data[4] +
              data[5] +
              data[6] +
              data[7] +
              data[8] +
              data[9],
            data[10],
            data[11],
            data[12],
            data[13],
            data[14],
            data[15],
            data[16],
            data[17],
            data[18] + data[19] + data[20] + data[21] + data[22] + data[23],
          ],
          backgroundColor: blue,
        },
      ],
    }),
    [data]
  );

  return (
    <Box height={1}>
      <Bar data={chartData} options={hourlySalesChartOptions}></Bar>
    </Box>
  );
}
