import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import { green } from 'controlTower/chartUtils/colors';
import { formatCurrencyNum, format1 } from 'utils/utils';
import { useFetchWidgetData } from './../ctHooks';

const chartOptions = {
  maintainAspectRatio: false,
  responsive: true,
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        let label = data.datasets[tooltipItem.datasetIndex].label || '';
        if (label) {
          label += ': ';
        }
        label +=
          tooltipItem.datasetIndex === 0
            ? formatCurrencyNum(tooltipItem.yLabel)
            : tooltipItem.yLabel;
        return label;
      },
    },
  },
  title: {
    display: true,
    text: '',
  },
  plugins: {
    datalabels: {
      align: 'end',
      anchor: 'end',
      formatter: (value, ctx) => {
        if (!value) return '';
        return format1((100 * value) / ctx.dataset.data.reduce((a, b) => a + b)) + ' %';
      },
    },
  },
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        display: true,
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        type: 'linear',
        display: true,
        ticks: {
          beginAtZero: true,
          callback: (value, _, values) =>
            values[0] > 1000000 ? value / 1000000 + ' M' : value / 1000 + ' K',
        },
        gridLines: {
          display: false,
        },
        labels: {
          show: true,
        },
      },
    ],
  },
};

const c = {
  SMALL: 'Small',
  LARGE: 'Large',
  MM: 'MM',
  SUBD: 'SubD',
  MEDIUM: 'Medium',
  WS: 'WS',
};
const grouping = {
  'Small C Traditional': c.SMALL,
  'Large B Pharmacy': c.LARGE,
  'MM 1': c.MM,
  'Semi WS Traditional': c.WS,
  'Small B Traditional': c.SMALL,
  'SubD A': c.SUBD,
  'New Traditional': c.SMALL,
  'MM 2': c.MM,
  'Medium Pharmacy': c.MEDIUM,
  'Small A Traditional': c.SMALL,
  'Semi WS Beauty & Pharmacy': c.WS,
  'Small A Pharmacy': c.SMALL,
  'Medium Beauty': c.MEDIUM,
  'WS Beauty & Pharmacy': c.WS,
  'Large B Traditional': c.LARGE,
  'Large A Traditional': c.LARGE,
  'WS Traditional': c.WS,
  'New Pharmacy': c.SMALL,
  'Large A Pharmacy': c.LARGE,
  Speciality: c.SMALL,
  Hyper: c.MM,
  Super: c.MM,
  'Large A Beauty': c.LARGE,
  'Small A Beauty': c.SMALL,
  'Medium Traditional': c.MEDIUM,
};

function processBrandData(data) {
  const channels = { [c.SMALL]: 0, [c.MEDIUM]: 0, [c.SUBD]: 0, [c.MM]: 0, [c.LARGE]: 0, [c.WS]: 0 };
  if (!data || !data.brands) return channels;
  const rawChannels = data.brands.flatMap((b) => b.channels);
  rawChannels.forEach(({ channel, value }) => {
    channels[grouping[channel]] += value;
  });
  return channels;
}

export default function SalesDistribution({ url, branchesView, branchData, setPenultimate }) {
  const data = useFetchWidgetData({ url, branchesView, branchData, setPenultimate });

  const chartData = useMemo(() => {
    const channels = processBrandData(data);
    return {
      labels: [c.SMALL, c.MEDIUM, c.LARGE, c.WS, c.MM, c.SUBD],
      datasets: [
        {
          label: 'Value',
          data: data
            ? [
                channels[c.SMALL],
                channels[c.MEDIUM],
                channels[c.LARGE],
                channels[c.WS],
                channels[c.MM],
                channels[c.SUBD],
              ]
            : [0, 0, 0, 0, 0, 0],
          backgroundColor: green,
          // datalabels: {
          //   display: false,
          // },
        },
      ],
    };
  }, [data]);

  if (!data) return null;

  return (
    <Box height={1}>
      <Bar data={chartData} options={chartOptions}></Bar>
    </Box>
  );
}
