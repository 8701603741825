import { Box } from '@material-ui/core';
import { blue } from 'controlTower/chartUtils/colors';
import React from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import { useFetchWidgetData } from '../ctHooks';

const funnelOptions = {
  maintainAspectRatio: false,
  responsive: true,
  legend: {
    display: false,
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        return data?.datasets[0]?.label?.[Number(tooltipItem?.index)][0];
      },
      afterBody: function (tooltipItem, data) {
        return data?.datasets[0]?.label?.[Number(tooltipItem[0]?.index)]?.slice(1);
      },
    },
  },
  plugins: {
    datalabels: {
      color: 'rgb(0,0,0,0.87)',
      font: {
        weight: 400,
      },
      clamp: true,
    },
  },

  scales: {
    yAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

export default function AdherenceMetrics_Marico_Rural_VSR({
  url,
  branchesView,
  branchData,
  setPenultimate,
}) {
  const data = useFetchWidgetData({ url, branchesView, branchData, setPenultimate });
  const {
    salesmanCount,
    reportedCount,
    plannedBeatCompliance,
    beatCompliantReported,
  } = data.vsrMetrics;
  const funnelData = {
    labels: ['VSR Universe', 'VSRs Present', 'Town Adherent VSRs', 'Geo Compliant VSRs'],
    datasets: [
      {
        data: [salesmanCount, reportedCount, plannedBeatCompliance, beatCompliantReported],
        backgroundColor: blue,
        label: [
          ['No. of VSRs active'],
          ['No. of VSRs that have reported'],
          ['No. of VSRs who have taken order from', ' the planned town as per PJP'],
          ['No. of VSRs present at location of', ' Planned town as per PJP'],
        ],
      },
    ],
  };

  return (
    <Box height={1} flexDirection="column" display="flex" justifyContent="space-evenly">
      <HorizontalBar options={funnelOptions} data={funnelData} />
    </Box>
  );
}
