import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box } from '@material-ui/core';
import { useCurrencyFormatter } from 'utils/customHooks';
import { HorizontalBar, Doughnut } from 'react-chartjs-2';
import { format0 } from 'utils/utils';
import { green, red } from 'controlTower/chartUtils/colors';
import { useFetchWidgetData } from './../ctHooks';

const useStyles = makeStyles((theme) => ({
  bottomGridItem: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '50%',
    },
  },
}));

const getHorizontalBarChartOptions = (formatCurrency) => ({
  maintainAspectRatio: false,
  responsive: true,
  legend: {
    display: true,
    labels: {
      // padding: 0,
    },
    position: 'bottom',
  },
  plugins: {
    datalabels: {
      color: 'rgb(0,0,0,0.87)',
      font: {
        weight: 400,
      },
      formatter: (value, ctx) => {
        if (!value) return '';
        if (value / ctx.dataset.data[0] < 0.05) return '';
        return ctx.dataIndex
          ? `${format0((100 * value) / ctx.dataset.data[0])}%`
          : formatCurrency(value);
      },
    },
  },

  title: {
    display: true,
    text: 'Collection Status',
  },
  scales: {
    xAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
          display: false,
        },
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
        gridLines: {
          display: false,
        },
      },
    ],
  },
});

const salesChartOptions = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    datalabels: {
      display: false,
    },
  },
  legend: {
    position: 'bottom',
  },
  title: {
    text: 'Credit by Mode',
    display: true,
    position: 'top',
  },
};

const deliveryChartOptions = {
  maintainAspectRatio: false,
  responsive: true,
  legend: {
    position: 'bottom',
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
  title: {
    text: 'COD by Mode',
    display: true,
    position: 'top',
  },
};

export default function Collection({ url, branchesView, branch, setPenultimate }) {
  const classes = useStyles();
  const data = useFetchWidgetData({ url, branchesView, branch, setPenultimate });
  const formatCurrency = useCurrencyFormatter();

  const horizontalBarChartOptions = useMemo(() => getHorizontalBarChartOptions(formatCurrency), [
    formatCurrency,
  ]);

  const totalChartData = useMemo(
    () => ({
      labels: ['Planned', 'Collected'],
      datasets: [
        {
          label: 'Credit',
          data:
            data.total && data.collected
              ? // ? [dataToUse.total.sales, dataToUse.sales.reduce((acc, ent) => acc + ent.value, 0)]
                [data.total.sales, data.collected.sales]
              : [0, 0],
          // backgroundColor: [yellowDark, yellowLight],
          backgroundColor: [red, red],
        },
        {
          label: 'COD',
          data:
            data.total && data.collected
              ? // ? [dataToUse.total.delivery, dataToUse.delivery.reduce((acc, ent) => acc + ent.value, 0)]
                [data.total.delivery, data.collected.delivery]
              : [0, 0],
          // backgroundColor: [blueDark, blueLight],
          backgroundColor: [green, green],
        },
      ],
    }),
    [data]
  );

  const salesChartData = useMemo(
    () => ({
      labels: data.sales.map((d) => d.mode),
      datasets: [
        {
          label: 'Sales',
          data: data ? data.sales.map((d) => d.value) : [0],
          backgroundColor: [
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(75, 192, 192, 0.6)',
          ],
        },
      ],
    }),
    [data]
  );

  const deliveryChartData = useMemo(
    () => ({
      labels: data.delivery.map((d) => d.mode),
      datasets: [
        {
          label: 'Delivery',
          data: data ? data.delivery.map((d) => d.value) : [0],
          backgroundColor: [
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(75, 192, 192, 0.6)',
          ],
        },
      ],
    }),
    [data]
  );

  return (
    <Box height={1} flexDirection="column" display="flex" justifyContent="center">
      <Box height={2 / 5}>
        <HorizontalBar data={totalChartData} options={horizontalBarChartOptions} />
      </Box>
      <Box height={3 / 5} pt={1}>
        <Grid
          container
          justify="space-around"
          alignItems="center"
          style={{ height: '100%' }}
          spacing={1}
        >
          <Grid item xs={12} sm={6} className={classes.bottomGridItem}>
            <Doughnut data={salesChartData} options={salesChartOptions} />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.bottomGridItem}>
            <Doughnut data={deliveryChartData} options={deliveryChartOptions} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
